import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {rootUrl} from '../../../helpers/utilities';

import {destroyDocument} from '../redux/actions/selectedNodeActions';

const InputDocument = ({selectedNode, destroyDocument}) => {

  const [document, setDocument] = useState(selectedNode.document)

  useEffect(() => {
    setDocument(selectedNode.document)
  }, [selectedNode])

  const handleDeleteDocument = (id) => {
    destroyDocument(id);
  }

  const renderDocument = () => {
    if (document) {
      return (
        <>
          <div>Attached document</div>
          <a className="link" target="_blank" rel="noreferrer" href={document.url}>{document.name}</a>
          <div className="link" onClick={() => handleDeleteDocument(document.id)}>Destroy</div>
          <input type="hidden" id={`document`} defaultValue={document.key}/>
        </>
      )
    } else {
      return (
        <div className="dropzone dropzone-default dz-clickable" data-controller="dropzone" data-dropzone-max-file-size="2" data-dropzone-max-files="1">
          <div className={`form-group file optional document`}>
            <label className="file optional" htmlFor={`document`}>document</label>
            <input 
              data-direct-upload-url={`${rootUrl}/rails/active_storage/direct_uploads`}
              className="form-control-file file optional" 
              type="file" 
              name={`document`} 
              id={`document`} 
              data-dropzone-target="input" 
              disabled="" 
              style={{display: 'none'}} />
          </div>
          <div className="dz-default dz-message">
            <button className="dz-button" type="button">Drop files here to upload or click to browse</button>
          </div>
          <span className="dropzone-msg-desc text-sm">
            <p>2 MB file size maximum</p>
          </span>
        </div>
    )}
  }

  return (
    renderDocument()
  )
}

const mapStateToProps = state => {
  return {
    selectedNode: state.selectedNode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyDocument: (id) => dispatch(destroyDocument(id)),
  }
}


InputDocument.propTypes = {
  // From Redux
  node: PropTypes.string,
  destroyDocuement: PropTypes.func,
  selectedNode: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputDocument)
