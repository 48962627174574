import React from 'react'
import Modal from "react-bootstrap/Modal";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import FormAddNode from '../forms-add/FormAddNode';

const ButtonAddNeed = ({node}) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  }

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button onClick={showModal} className="button_add_node" id={`ButtonAdd${node}`}>
      </button>
      <Modal show={isOpen} onHide={hideModal} id={`ModalAddneed`}>
        <Modal.Header>
          <h5 className="modal-title">{`Add an ${node}`}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" onClick={hideModal}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormAddNode node={node} setIsOpen={setIsOpen}/>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    challengeId: state.challengeId,
    token: state.token,
  }
}

ButtonAddNeed.propTypes = {
  node: PropTypes.string,
  token: PropTypes.string,
  challengeId: PropTypes.string,
}

export default connect(mapStateToProps, null)(ButtonAddNeed)
