import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Task from './Task'

const Validated = ({
  // Redux
  selectedNode
}) => {

  return (
    <>
      <i className="fas fa-sort-up task-title-arrow"></i>
      <div className="task-title">Validated</div>
      <div className='container-task'>
        <div className="row d-flex justify-content-center task-row">
          <Task 
            title="Patent strategy" attr='patent_strategy' 
            status={selectedNode.progress.patent_strategy_status} 
            user_name={selectedNode.progress.patent_strategy_user.name} />
            <Task
            title="Launch Team" attr='final_specifications' 
            status={selectedNode.progress.final_specifications_status} 
            user_name={selectedNode.progress.final_specifications_user.name} />
          <Task 
            title="Launch Presentation" attr='final_usage_study' 
            status={selectedNode.progress.final_usage_study_status} 
            user_name={selectedNode.progress.final_usage_study_user.name} />
          <Task 
            title="Com Strategy" attr='pre_series_created' 
            status={selectedNode.progress.pre_series_created_status} 
            user_name={selectedNode.progress.pre_series_created_user.name} />
        </div>
        <div className="row d-flex justify-content-center task-row">
          <Task 
            title="Beta Testing" attr='beta_tester_report' 
            status={selectedNode.progress.beta_tester_report_status} 
            user_name={selectedNode.progress.beta_tester_report_user.name} />
          <Task 
            title="New Pictures" attr='launch_plan' 
            status={selectedNode.progress.launch_plan_status} 
            user_name={selectedNode.progress.launch_plan_user.name} />
          <Task 
            title="Go from authority" attr='go_from_authority_validated' 
            status={selectedNode.progress.go_from_authority_validated_status} 
            user_name={selectedNode.progress.go_from_authority_validated_user.name} />
          </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode
  }
} 

Validated.propTypes = {
  selectedNode: PropTypes.object,
}

export default connect(mapStateToProps, null)(Validated)