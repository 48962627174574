import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const InputKnowledgeCategory = ({formik, knowledgeCategories}) => {
  const renderOptions = knowledgeCategories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)

  return (
    <div>
      <label className="select required" htmlFor="knowledge_category_id">Knowledge Category<abbr title="required">*</abbr></label>
      <select 
        className={formik.touched.knowledge_category_id && formik.errors.knowledge_category_id ? "form-control select required is-invalid" : "select required form-control"}
        name="knowledge_category_id" 
        id="knowledge_category_id"
        value={formik.values.knowledge_category_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <option value="Select a category">Select a category</option>
        {renderOptions}
      </select>
      {formik.touched.knowledge_category_id && formik.errors.knowledge_category_id ? <div className="alert-form">{formik.errors.knowledge_category_id}</div> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    knowledgeCategories: state.knowledgeCategories
  }
}

InputKnowledgeCategory.propTypes = {
  formik: PropTypes.object,
  knowledgeCategories: PropTypes.array
}

export default connect(mapStateToProps, null)(InputKnowledgeCategory);
