import React from 'react';
import PropTypes from 'prop-types';

const InputSource = ({formik}) => {
  return (
    <div>
      <label htmlFor='source'>Source</label>
      <input
        id='source'
        name='source'
        type="textarea"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.source}
        className = {formik.touched.source && formik.errors.source ? "form-control is-invalid" : "form-control"}
      />
      {formik.touched.source && formik.errors.source ? <div className="alert-form">{formik.errors.source}</div> : null}
    </div>
  )
}


InputSource.propTypes = {
  formik: PropTypes.object
}

export default InputSource
