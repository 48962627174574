import {
  GET_CURRENT_USER
} from './actionsTypes';


export const getCurrentUser = (currentUser) => {
  return {
    type: GET_CURRENT_USER,
    payload: currentUser
  }
}