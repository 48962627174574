// FONCTIONS CALLBACK DE DESSIN CANVA

export const roundedPoly = function(points,radius, ctx){
  let i, x, y, len, p1, p2, p3, v1, v2, sinA, sinA90, radDirection, drawDirection, angle, halfAngle, cRadius, lenOut;
  const asVec = function (p, pp, v) { // convert points to a line with len and normalised
      v.x = pp.x - p.x; // x,y as vec
      v.y = pp.y - p.y;
      v.len = Math.sqrt(v.x * v.x + v.y * v.y); // length of vec
      v.nx = v.x / v.len; // normalised
      v.ny = v.y / v.len;
      v.ang = Math.atan2(v.ny, v.nx); // direction of vec
  }
  v1 = {};
  v2 = {};
  len = points.length;                         // number points
  p1 = points[len - 1];                        // start at end of path
  for (i = 0; i < len; i++) {                  // do each corner
      p2 = points[(i) % len];                  // the corner point that is being rounded
      p3 = points[(i + 1) % len];
      // get the corner as vectors out away from corner
      asVec(p2, p1, v1);                       // vec back from corner point
      asVec(p2, p3, v2);                       // vec forward from corner point
      // get corners cross product (asin of angle)
      sinA = v1.nx * v2.ny - v1.ny * v2.nx;    // cross product
      // get cross product of first line and perpendicular second line
      sinA90 = v1.nx * v2.nx - v1.ny * -v2.ny; // cross product to normal of line 2
      angle = Math.asin(sinA);                 // get the angle
      radDirection = 1;                        // may need to reverse the radius
      drawDirection = false;                   // may need to draw the arc anticlockwise
      // find the correct quadrant for circle center
      if (sinA90 < 0) {
          if (angle < 0) {
              angle = Math.PI + angle; // add 180 to move us to the 3 quadrant
          } else {
              angle = Math.PI - angle; // move back into the 2nd quadrant
              radDirection = -1;
              drawDirection = true;
          }
      } else {
          if (angle > 0) {
              radDirection = -1;
              drawDirection = true;
          }
      }
      halfAngle = angle / 2;
      // get distance from corner to point where round corner touches line
      lenOut = Math.abs(Math.cos(halfAngle) * radius / Math.sin(halfAngle));
      if (lenOut > Math.min(v1.len / 2, v2.len / 2)) { // fix if longer than half line length
          lenOut = Math.min(v1.len / 2, v2.len / 2);
          // ajust the radius of corner rounding to fit
          cRadius = Math.abs(lenOut * Math.sin(halfAngle) / Math.cos(halfAngle));
      } else {
          cRadius = radius;
      }
      x = p2.x + v2.nx * lenOut; // move out from corner along second line to point where rounded circle touches
      y = p2.y + v2.ny * lenOut;
      x += -v2.ny * cRadius * radDirection; // move away from line to circle center
      y += v2.nx * cRadius * radDirection;
      // x,y is the rounded corner circle center
      ctx.arc(x, y, cRadius, v1.ang + Math.PI / 2 * radDirection, v2.ang - Math.PI / 2 * radDirection, drawDirection); // draw the arc clockwise
      p1 = p2;
      p2 = p3;
  }
  ctx.closePath();
}

const blackGround = "#3d41a8"
const textColor = 'black'

// FUNCTIONS TO CALLBACK
// TRIANGLE
const triangleFillDesign = (ctx, node) => {
  const radius = 5
  const width = 2 * node.val * 1.5
  // Dessine les contours du triangle
  ctx.beginPath();
  const points = [
    {x: node.x - node.val * 1.5, y: node.y + node.val},
    {x: node.x + node.val * 1.5, y: node.y + node.val},
    {x: node.x, y: node.y - node.val / 1.5},
  ]
  roundedPoly(points, radius, ctx)
  // Change la couleur et rempli le triangle
  ctx.fillStyle = node.color;
  ctx.fill();
  ctx.closePath();
  textDesignTriangle(ctx, node, width)
}

const triangleStrokeDesign = (ctx, node) => {
  const radius = 5
  const width = 2 * node.val * 1.5
  // Dessine les contours du triangle et arrondi les bords
  ctx.beginPath();
  const points = [
    {x: node.x - node.val * 1.5, y: node.y + node.val},
    {x: node.x + node.val * 1.5, y: node.y + node.val},
    {x: node.x, y: node.y - node.val / 1.5},
  ]
  roundedPoly(points, radius, ctx)
  // Change la couleur et applique les strokes
  ctx.strokeStyle = node.color;
  ctx.stroke()
  // Défini une couleur de fond noir et rempli le triangle
  ctx.fillStyle = blackGround;
  ctx.fill()
  ctx.closePath();
  textDesignTriangle(ctx, node, width)
}

const outlineStrokeTriangleDesign = (ctx, node) => {
  const radius = 10
  // Dessine les contours du triangle (les dimensions sont agrandies par rapport au triangle "normal")
  ctx.beginPath();
  const points = [
    {x: node.x - node.val * 1.5 - 15, y: node.y + node.val + 7.5},
    {x: node.x + node.val * 1.5 + 15, y: node.y + node.val + 7.5},
    {x: node.x, y: node.y - node.val / 1.5 - 15},
  ]
  roundedPoly(points, radius, ctx)
  // Change la couleur et applique les strokes
  ctx.setLineDash([]) /*No dash*/
  ctx.strokeStyle = node.color;
  ctx.stroke();
  // Défini une couleur de fond noir et rempli le triangle
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();
}

// CIRCLE
const circleStrokeDesign = (ctx, node) => {
  const width = 2 * node.val

  ctx.beginPath();
  ctx.arc(node.x, node.y, width / 2, 0, 2 * Math.PI, false);
  // Change la couleur et applique les strokes
  ctx.strokeStyle = node.color;
  ctx.stroke();
  // Défini une couleur de fond noir et rempli le cercle
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();

  textDesignCircle(ctx, node, width)
}

const circleFillDesign = (ctx, node) => {
  const width = 2 * node.val

  ctx.beginPath();
  ctx.arc(node.x, node.y, width / 2, 0, 2 * Math.PI, false);
  // Défini la couleur de fond et rempli le cercle
  ctx.fillStyle = node.color;
  ctx.fill();
  ctx.closePath();

  textDesignCircle(ctx, node, width)
}

const circleOutlineDashedDesign = (ctx, node) => {
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val * 1.2, 0, 2 * Math.PI, false);
  // Change la couleur et applique les strokes
  ctx.setLineDash([5,5]); /*dashes are 5px and spaces are 5px*/
  ctx.strokeStyle = node.color;
  ctx.stroke();
  // Défini une couleur de fond noir et rempli le cercle
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();
}

const circleOutlineDesign = (ctx, node) => {
  // Rayon = largeur du texte * 0.82 être légèrement supérieur au cercle "principal" (0.65)
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val * 1.2, 0, 2 * Math.PI, false);
  // Change la couleur et applique les strokes
  ctx.setLineDash([]); /*no Dash*/
  ctx.strokeStyle = node.color;
  ctx.stroke();
  // Défini une couleur de fond noir et rempli le cercle
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();
}

const circleOutlineOutlineDesign = (ctx, node) => {
  // Rayon = largeur du texte pour être légèrement supérieur au cercle "principal" (0.65) et "secondaire" (0.82)
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val * 1.4, 0, 2 * Math.PI, false);
  // Change la couleur et applique les strokes
  ctx.setLineDash([]); /*no Dash*/
  ctx.strokeStyle = node.color;
  ctx.stroke();
  // Défini une couleur de fond noir et rempli le cercle
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();
}

// RECTANGLE
const roundedRectangleWithLines = (ctx, node) => {
  const radius = 5
  const height = 50
  const width = 30
  const centerX = node.x - width / 2
  const centerY = node.y - height / 2
  // Dessine les contours du triangle
  ctx.beginPath();
  const points = [
    {x: centerX , y: centerY },
    {x: centerX + width, y: centerY},
    {x: centerX + width, y: centerY + height},
    {x: centerX, y: centerY + height},
  ]
  roundedPoly(points, radius, ctx)
  // Change la couleur et rempli le triangle
  ctx.setLineDash([]); /*no Dash*/
  ctx.strokeStyle = node.color;
  ctx.stroke();
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.closePath();
  // Dessine les lignes
  ctx.beginPath();
  ctx.moveTo( centerX + 5, centerY + 15);
  ctx.lineTo( centerX + 25, centerY + 15);
  ctx.moveTo( centerX + 5, centerY + 25);
  ctx.lineTo( centerX + 25,centerY + 25);
  ctx.moveTo( centerX + 5, centerY + 35);
  ctx.lineTo( centerX + 25, centerY + 35);
  ctx.stroke();
  ctx.closePath();
}

export const roundedRectangle = (ctx, node, height, width) => {
  // Dessine le rectangle avec radius
  const radius = 5
  const centerX = node.x - width / 2
  const centerY = node.y - height / 2
  // Dessine le rectangle
  ctx.beginPath();
  ctx.moveTo( centerX + radius, centerY);
  ctx.arcTo( centerX + width, centerY, centerX + width, centerY + height, radius);
  ctx.arcTo( centerX + width, centerY + height, centerX, centerY + height, radius);
  ctx.arcTo( centerX, centerY + height, centerX, centerY, radius);
  ctx.arcTo( centerX, centerY, centerX + width, centerY, radius);
  ctx.fillStyle = blackGround;
  ctx.fill();
  ctx.strokeStyle = node.color;
  ctx.stroke();
  ctx.closePath();
}

const FONT_SIZE = 8;

// TEXTES

const changeTitleWidth = (ctx, node, limitWidth) => {
  let title = node.name
  let loop = false
  while(ctx.measureText(title).width > limitWidth) {
    title = title.slice(0, -1)
    loop = true
  }
  if(loop) { title = `${title}...` }
  return title
}

const  fillTextMultiLine = (ctx, title, x, y) => {
  const lineHeight = ctx.measureText(title).fontBoundingBoxAscent * 1.7;
  const lines = title.split("\n").filter(Boolean);
  const numberLines = 2;
  if (lines.length >= numberLines) {
    for (let i = 0; i < numberLines; ++i) {
      const text = i === numberLines - 1 && lines.length > numberLines ? `${lines[i].trim()}...` : lines[i].trim()
      ctx.fillText(text, x, y - 3);
      y += lineHeight;
    }
  } else {
    ctx.fillText(title, x, y);
  }
}

const addBreaksToText = (ctx, node, limitWidth) => {
  const title = node.name
  const titleSplit = title.split(' ');
  let text = '';
  let newLine = '';
  titleSplit.forEach(word => {
    const analyzeLine = newLine.trim() || text.trim()
    if (ctx.measureText(analyzeLine).width < limitWidth) {
      if(newLine) newLine += ` ${word} `
      text += ` ${word}`
    } else {
      if(node.type === 'Need') { limitWidth += 20 }
      newLine = ` ${word}`;
      text += `\n${word}`
    }
  })
  return text.trim()
}

const textDesignCircle = (ctx, node, width) => {
  ctx.font = `${FONT_SIZE}px Sans-Serif`;

  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  // Si la couleur du noeud est blanc (=> pas de fond) ou si il est sélectionné alors qu'il était blanc de base alors le texte est blanc sinon noir
  if(node.color_old == '#FFFFFF' && node.color_selected) {
    ctx.fillStyle = 'white'
  } else if (node.color == '#FFFFFF') {
    ctx.fillStyle = 'white'
  } else {
    ctx.fillStyle = textColor
  }

  // Change the title depending on the width
  const limitWidthCircle = width - 32
  const title = addBreaksToText(ctx, node, limitWidthCircle)
  fillTextMultiLine(ctx, title, node.x, node.y)
  }

const textDesignTriangle = (ctx, node, width) => {
  ctx.font = `${FONT_SIZE}px Sans-Serif`;

  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  // Si la couleur du noeud est blanc (=> pas de fond) ou si il est sélectionné alors qu'il était blanc de base alors le texte est blanc sinon noir
  if(node.color_old == '#FFFFFF' && node.color_selected) {
    ctx.fillStyle = 'white'
  } else if (node.color == '#FFFFFF') {
    ctx.fillStyle = 'white'
  } else {
    ctx.fillStyle = textColor
  }

  // Change the title depending on the width
  const limitWidthTriangle = width - 60;
  const title = addBreaksToText(ctx, node, limitWidthTriangle);
  fillTextMultiLine(ctx, title, node.x, node.y + node.val - 15);
}

const textDesignRect = (ctx, node, height, width) => {
  // Desining title
  const fontSizeTitle = 5;
  ctx.font = `${fontSizeTitle}px Comfortaa`;
  ctx.fillStyle = 'white'
  const positionTextX = node.x - 22
  const widthText = (width / 1.5) - 5
  const title = changeTitleWidth(ctx, node, widthText)
  ctx.fillText(title, positionTextX, node.y - 15);

  // Desining description
  const fontSizeDesc = 3;
  ctx.font = `${fontSizeDesc}px Roboto`;
  ctx.fillStyle = 'white';
  // Longueur de la ligne de mots
  let sentence = ""
  let arrayWordsPerLine = []
  let y = node.y - 5
  let countLines = 0
  const arrayWords = node.description.split(' ')
  arrayWords.every(word => {
    sentence += word
    // Si il y a plus de 6 lignes on arrête le loop
    if(countLines > 5) {
      return false
    }
    // Si la phrade est supérieure à l'espace dédié pour le txt, on ajoute la ligne sans le dernier mot
    else if (ctx.measureText(sentence).width > widthText - 20) {
      // Si on est sur la dernière ligne on ajoute 3 points
      if(countLines === 5) arrayWordsPerLine.push("...")
      ctx.fillText(arrayWordsPerLine.join(' '), positionTextX, y);
      y += 5
      sentence = ""
      arrayWordsPerLine = []
      countLines ++
      // Si on est pas sur la derniere ligne on ajoute sinon pas besoin
      if(countLines < 5) arrayWordsPerLine.push(word)
    }
    // Si on est sur le dernier mot on ajoute la ligne
    else if (word === arrayWords[arrayWords.length -1]) {
      arrayWordsPerLine.push(word)
      ctx.fillText(arrayWordsPerLine.join(' '), positionTextX, y);
    } 
    // Sinon on ajoute le mot à la liste que l'on va copier
    else {
      arrayWordsPerLine.push(word)
    }
    return true;
  })

  // Desining Image
  // Fonction pour créer une zone arrondie pour l'image
  const roundedImage = (x, y, height, width, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }

  const drawImage = (node, ctx, base_image) => {
    // First - Design a rounded rectangle
    ctx.save()
    roundedImage(node.x - width / 2 + 0.5, node.y - height / 2 + 0.5, width / 3 - 1, height - 1, 5)
    // Clip the image inside the rounde rectangle
    ctx.clip()
    // Draw the image
    ctx.drawImage(base_image, node.x - width / 2 + 0.5, node.y - height / 2 + 0.5, width / 3 - 1, height - 1);
    ctx.restore();
  }

  // Design l'image
  if (node.hasOwnProperty('photo')) {
    const base_image = new Image();
    if(node.photo) base_image.src = node.photo;
    // Si l'image existe déjà elle est affiché
    drawImage(node, ctx, base_image)
    base_image.onload = function() {
      // affiche l'image après le premier chargement de la page
      drawImage(node, ctx, base_image)
    }
  }
}

// FONCTION DE DESSIN DES ELEMENTS CANVAS

// RECTANGLE
export const canvaTextRect = (node, ctx, globalScale) => {
  const height = 50
  const width = 150
  roundedRectangle(ctx, node, height, width);
  textDesignRect(ctx, node, height, width);
}

export const canvaTextroundedRectangleWithLines = (node, ctx, globalScale) => {
  // Design rectangle with radius
  roundedRectangleWithLines(ctx, node);

  node.__bckgDimensions = node.val;
}

// Triangle
export const canvaTextTriangleStrokeDashed = (node, ctx, globalScale) => {
  // Change the caracteritic of the strokes
  ctx.setLineDash([5, 5]) /*dashes are 5px and spaces are 5px*/
  // Define the triangle element
  triangleStrokeDesign(ctx, node)
  
  node.__bckgDimensions = node.val;
}

export const canvaTextTriangleStroke = (node, ctx, globalScale) => {
  ctx.setLineDash([]) /*dashes are 5px and spaces are 5px*/
  // Define the triangle element
  triangleStrokeDesign(ctx, node)

  node.__bckgDimensions = node.val;
}

export const canvaTextTriangleFilled = (node, ctx, globalScale) => {
  // Define the triangle element
  triangleFillDesign(ctx, node)

  node.__bckgDimensions = node.val;
}

export const canvaTextTriangleFilledAndStroke = (node, ctx, globalScale) => {
  // On recrée les stroke pour avoir un décalage de entre le Fill et la Stroke
  outlineStrokeTriangleDesign(ctx, node)
  // Filled the form (DOIT ETRE APRES LES STROKES CAR ON FILL UNE DEUXIEME FOIS L'INTERIEUR DU TRIANGLE)
  triangleFillDesign(ctx, node)

  node.__bckgDimensions = node.val;
}

// Circle
export const canvaTextCircleStrokeDashed = (node, ctx, globalScale) => {
  // Change the caracteritic of the strokes
  ctx.setLineDash([5,5]) /*dashes are 5px and spaces are 5px*/
  // Design le cercle
  circleStrokeDesign(ctx, node)
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleStrokeAndStrokeDashed = (node, ctx, globalScale) => {
  // Design le cercle
  circleOutlineDashedDesign(ctx, node);
  ctx.setLineDash([]); /*No dash*/
  circleStrokeDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleStrokeAndStroke = (node, ctx, globalScale) => {
  // Design le cercle
  circleOutlineDesign(ctx, node);
  ctx.setLineDash([]); /*No dash*/
  circleStrokeDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleFill = (node, ctx, globalScale) => {
  // Design circle
  circleFillDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleFillAndStrokeDashed = (node, ctx, globalScale) => {
  // Design circle
  circleOutlineDashedDesign(ctx, node);
  circleFillDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleFillAndStroke = (node, ctx, globalScale) => {
  // Design circle
  circleOutlineDesign(ctx, node);
  circleFillDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

export const canvaTextCircleFillAndStrokeAndStroke = (node, ctx, globalScale) => {
  // Design circle
  circleOutlineOutlineDesign(ctx, node);
  circleOutlineDesign(ctx, node);
  circleFillDesign(ctx, node);
  node.__bckgDimensions = node.val;
}

// FONCTION DE DESSIN DES POINTEURS

export const pointerCircleSmall = (node, ctx) => {// Dessine le background du cercle
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.closePath();
}

export const pointerCircleMedium = (node, ctx) => {// Dessine le background du cercle
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val * 1.2, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.closePath();
}

export const pointerCircleLarge = (node, ctx) => {// Dessine le background du cercle
  ctx.beginPath();
  ctx.arc(node.x, node.y, node.val * 1.4, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.closePath();
}

export const pointerTriangleSmall = (node, ctx) => {
  const radius = 5
  ctx.beginPath();
  const points = [
    {x: node.x - node.val * 1.5, y: node.y + node.val},
    {x: node.x + node.val * 1.5, y: node.y + node.val},
    {x: node.x, y: node.y - node.val / 1.5}
  ]
  roundedPoly(points, radius, ctx)
  ctx.fill();
  ctx.closePath();
}

export const pointerTriangleLarge = (node, ctx) => {
  const radius = 10
  ctx.beginPath();
  const points = [
    {x: node.x - node.val * 1.5 - 15, y: node.y + node.val + 7.5},
    {x: node.x + node.val * 1.5 + 15, y: node.y + node.val + 7.5},
    {x: node.x, y: node.y - node.val / 1.5 - 15},
  ]
  roundedPoly(points, radius, ctx)
  ctx.fill();
  ctx.closePath();
}