import ReactDOM from 'react-dom';
import React from 'react';

import MindMapUniverse from '../mindmap-universe/MindMapUniverse';
import RootContainer from '../mindmap-challenge/RootContainer';
import CarouselUniverse from '../mindmap-universe/CarouselUniverse';

const renderGraph = () => {
  const grahDataUniverse = document.getElementById('react-graphDataUniverse')
  const grahDataChallenge = document.getElementById('react-graphDataChallenge')
  const graphDataUniverseCarousel = document.getElementById('react-graphDataUniverseCarousel')

  if(grahDataUniverse) {
    const universeId = parseInt(grahDataUniverse.dataset.universeId,10)
    const universeTitle = grahDataUniverse.dataset.universeTitle
    ReactDOM.render(
      <MindMapUniverse universeId={universeId} universeTitle={universeTitle}/>,
      grahDataUniverse,
    );
  } else if (grahDataChallenge) {
    const props = grahDataChallenge.dataset.reactProps
    const propsMindMap = JSON.parse(props)
    ReactDOM.render(
      <RootContainer {... propsMindMap}/>,
      grahDataChallenge,
    );
  } else if (graphDataUniverseCarousel) {
    const universes = JSON.parse(graphDataUniverseCarousel.dataset.universes)
    ReactDOM.render(
      <CarouselUniverse universes={universes}/>,
      graphDataUniverseCarousel,
    );
  }
};

export default renderGraph;