import {
  GET_KNOWLEDGE_CATEGORIES
} from './actionsTypes';


export const getKnowledgeCategories = (knowledgeCategories) => {
  return {
    type: GET_KNOWLEDGE_CATEGORIES,
    payload: knowledgeCategories
  }
}