import {
  CLOSE_SELECTED_NODE, SELECT_NODE, DESTROY_THUMBNAIL, DESTROY_VIDEO, DESTROY_DOCUMENT,
  ADD_USER_TO_TEAM, UPDATE_TASK, ADD_COMMENT, DELETE_COMMENT, MODERATE_COMMENT,

  // GRAPHDATA ACTIONS
  UPDATE_NODE, EVOLVE_NODE, LIKE_SELECTED_NODE, UNLIKE_SELECTED_NODE
} from '../actions/actionsTypes';

export default function(state = {}, action) {
  switch(action.type) {
    case CLOSE_SELECTED_NODE:
      return {...action.payload}
    case ADD_COMMENT:
      return {...state, comments: state.comments.concat(action.payload)}
    case DELETE_COMMENT:
      return {...state, comments: state.comments.filter((comment) => comment.id !== action.payload.id)}
    case MODERATE_COMMENT:
      return {...state, comments: state.comments.map(comment => comment.id === action.payload.id ? action.payload : comment)}
    case LIKE_SELECTED_NODE:
      return {...state, likes: state.likes.concat(action.payload)}
    case UNLIKE_SELECTED_NODE:
      return {...state, likes: state.likes.filter((like) => like.id !== action.payload.id)}
    case SELECT_NODE:
      return {...action.payload}
    case DESTROY_THUMBNAIL:
      return {...action.payload}
    case DESTROY_VIDEO:
      return {...action.payload}
    case DESTROY_DOCUMENT:
      return {...action.payload}
    case ADD_USER_TO_TEAM:
      return {...action.payload}
    case UPDATE_TASK:
      return {...action.payload}
    // FROM THE GRAPHDATA ACTIONS, BUT WE NEED TO ACTUALIZE THE SELECTED NODE TOO
    // state = actual selectedNode
    // In Redux, our reducers are never allowed to mutate the original / current state values!
    // https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers
    // Thats why we use JavaScripts array / object spread operators and other functions that return copies of the original values.
    case UPDATE_NODE:
      return {...action.payload.nodes.find(node => node.id === state.id)}
    case EVOLVE_NODE:
      return {...action.payload.nodes.find(node => node.id === state.id)}
    default: return state
    
  }
}