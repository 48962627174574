import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {rootUrl} from '../../../helpers/utilities';

import {destroyDocument} from '../redux/actions/selectedNodeActions';

const InputDocuments = ({numFiles, selectedNode, destroyDocument}) => {
  const [documents, setDocuments] = useState(selectedNode.documents)

  useEffect(() => {
    setDocuments(selectedNode.documents)
  }, [selectedNode])

  const inputField = (number) => {
    return (
      <div 
        className="dropzone dropzone-default dz-clickable" 
        id='documents'
        data-controller="dropzone" 
        data-dropzone-max-file-size="2" 
        data-dropzone-max-files={`${number}`} 
      >
        <div className={`form-group file optional documents`}>
          <label className="file optional" htmlFor={`documents`}>Attached documents (max files {number} / 2 Mo max)</label>
          <input 
            data-direct-upload-url={`${rootUrl}/rails/active_storage/direct_uploads`}
            className="form-control-file file optional" 
            type="file"
            multiple="multiple"
            data-dropzone-target="input"
            name={`documents`} 
            id={'documents'}
            disabled=""
            style={{display: 'none'}}
          />
        </div>
        <div className="dz-default dz-message">
          <button className="dz-button" type="button">Drop files here to upload or click to browse</button>
        </div>
        <span className="dropzone-msg-desc text-sm">
          <p m='0'>Max files : {number}</p>
          <p m='0'>Max size per file : 2 MB</p>
        </span>
      </div>
    )
  }

  const renderDocuments = () => {
    if(documents && documents.length < 3) {
      const docs = documents.map(doc => {
        return (
          <React.Fragment key={doc.url}>
            <a className="link" target="_blank" rel="noreferrer" href={doc.url}>{doc.name}</a>
            <div className="link" onClick={() => destroyDocument(doc.id)}>Destroy</div>
            <input type="hidden" id={`documents`} defaultValue={doc.key} />
          </React.Fragment>
        )
      })
      return (
        <>
          {docs}
          {inputField(numFiles - documents.length)}
        </>
      )
    } else if (documents && documents.length >= 3) {
      return documents.map(doc => {
        return (
          <React.Fragment key={doc.url}>
            <a className="link" target="_blank" rel="noreferrer" href={doc.url}>{doc.name}</a>
            <div className="link" onClick={() => destroyDocument(doc.id)}>Destroy</div>
          </React.Fragment>
        )
      })

    } else {
      return (
        inputField(3)
      )
    }
  }

  return (
    renderDocuments()
  )
}

const mapStateToProps = state => {
  return {
    selectedNode: state.selectedNode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyDocument: (selectedNode) => dispatch(destroyDocument(selectedNode)),
  }
}


InputDocuments.propTypes = {
  // From Redux
  destroyDocument: PropTypes.func,
  selectedNode: PropTypes.object,
  numFiles: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(InputDocuments);
