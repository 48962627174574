import { SET_LINKED_NODE_ACTIVE, CLEAN_LINKED_NODE_ACTIVE } from "./actionsTypes";

export const setLinkedNodeActive = (node) => {
  return {
    type: SET_LINKED_NODE_ACTIVE,
    payload: node
  }
}

export const cleanLinkedNodeActive = () => {
  return {
    type: CLEAN_LINKED_NODE_ACTIVE,
    payload: {}
  }
}