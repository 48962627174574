import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateTask } from '../../../redux/actions/selectedNodeActions';

const Task = ({
  // Own Props
  title, status, user_name, attr,
  // Redux Props
  updateTask,
  statusTaskInactive,
  currentUser
}) => {


  return (
    <>
      <div className="col-2 container-card-task">
        <div className={`card-task w-100 ${status}`} 
        onClick={ !statusTaskInactive.includes(status) ? () => updateTask(attr, currentUser, status) : undefined}>
          {title}
        </div>
          <div className="font-size-8 text-title-font section-text-white d-flex justify-content-end m-1">
            {user_name}
          </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    statusTaskInactive: state.statusTaskInactive
  }
}

const dispatchStateToProps = (dispatch) => {
  return {
    updateTask: (attr, user, status) => dispatch(updateTask(attr, user, status))
  }
}


Task.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  attr: PropTypes.string,
  updateTask: PropTypes.func,
  currentUser: PropTypes.object,
  user_name: PropTypes.string,
  statusTaskInactive: PropTypes.array
}

export default connect(mapStateToProps, dispatchStateToProps)(Task)
