import React from 'react';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import {useFormik, Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { connect } from 'react-redux'

import { capitalize, aOrAn, rootUrl } from '../../../helpers/utilities';

import InputTitle from '../forms-input/InputTitle';
import InputContent from '../forms-input/InputContent';
import InputThumbnail from '../forms-input/InputThumbnail';
import InputVideo from '../forms-input/InputVideo';
import InputAuthor from '../forms-input/InputAuthor';
import InputSource from '../forms-input/InputSource';
import InputCorporate from '../forms-input/InputCorporate';
import InputKnowledgeCategory from '../forms-input/InputKnowledgeCategory';
import InputDocument from '../forms-input/InputDocument';


const ModalEditKnowledge = ({
  node, 
  challengeId, 
  token, 
  selectedNode,

  // Modal actions
  isOpen,
  hideModal,
}) => {
  const formik = useFormik({
    initialValues: {
      title: selectedNode.name,
      content: selectedNode.content,
      author: selectedNode.author,
      source: selectedNode.source,
      corporate: selectedNode.corporate, 
      knowledge_category_id: selectedNode.knowledge_category.id
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(80, 'Must be 80 characters or less')
        .required('Required'),
      content: Yup.string()
        .max(10000, 'Must be 10 000 characters or less')
        .required('Required'),
      author: Yup.string()
        .max(80, 'Must be 80 characters or less')
        .required('Required'),
      source: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .required('Required'),
      knowledge_category_id: Yup.string()
        .uuid('Please select a category')
        .required('Required')
    }),
    onSubmit: values => {

      // For the files we won't use the Formik logic but we retrieve them through formDatas and then merge them to the values
      const formData = {}
      formData['thumbnail'] = document.getElementById('thumbnail') && document.getElementById('thumbnail').value ? document.getElementById('thumbnail').value : null
      formData['video'] = document.getElementById('video') && document.getElementById('video').value ? document.getElementById('video').value : null
      formData['document'] = document.getElementById('document') && document.getElementById('document').value ? document.getElementById('document').value : null

      // Merging the data from Formik (values) and for the "classic way" (formData)
      const nodeValues = {...values, ...formData}

      const data = {
        'knowledge': nodeValues
      }

      axios({
        url: `${rootUrl}/knowledges/${selectedNode.knowledge_id}.json`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        method: 'patch',
        data: data
      })
      .then(() => {
        // Close and reset the modal
        hideModal();
      })
      .catch(error => {
        console.log(error)
        // Don't disable the btn
        const btnSubmit = document.getElementById(`submit-knowledge`);
        btnSubmit.disabled = false;
        // Render errors (see controller/api/v1/needs_controller)
        // We use this technique in order not to have the comma in the list
        let errorsMessage = ''
        error.response.data.errors.forEach(error => {
          errorsMessage += `<li>${error}</li>`
        })
        document.querySelector('body').insertAdjacentHTML('beforeend', 
          `<div class="alert alert-warning alert-dismissible fade show m-1" role="alert">
            ${errorsMessage}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`
        )
      })
    },
  });

  return (
    <Modal show={isOpen} onHide={hideModal} id={`ModalAdd${capitalize(node)}`}>
      <Modal.Header>
        <h5 className="modal-title">Update {aOrAn(node)} {`${node}`}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" onClick={hideModal}>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Formik
        initialValues={formik.initialValues}
        validate={formik.validationSchema}
        onSubmit={formik.onSubmit}
        >
        {({isValid, values}) => (
          <form onSubmit={formik.handleSubmit}>
            <InputTitle formik={formik} />
            <InputContent formik={formik} />
            <InputAuthor formik={formik} />
            <InputSource formik={formik} />
            <InputCorporate formik={formik} /> 
            <InputKnowledgeCategory formik={formik} /> 
            <InputThumbnail />
            <InputVideo />
            <InputDocument />

            <button type="submit" id={`submit-knowledge`}>Submit</button>
          </form>
        )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    challengeId: state.challengeId,
    token: state.token,
    selectedNode: state.selectedNode,
  }
}

ModalEditKnowledge.propTypes = {
  token: PropTypes.string,
  challengeId: PropTypes.string,
  selectedNode: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  node: PropTypes.string,
  hideModal: PropTypes.func
};

export default connect(mapStateToProps, null)(ModalEditKnowledge)
