import {
  GET_STATUS_TASK_INACTIVE
} from './actionsTypes';


export const getStatusTaskInactive = (status) => {
  return {
    type: GET_STATUS_TASK_INACTIVE,
    payload: status
  }
}