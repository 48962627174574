import React from "react";
import { ForceGraph2D } from "react-force-graph";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import {
  // Fonctions de dessin des canvas
  canvaTextroundedRectangleWithLines,
  canvaTextTriangleStroke,
  canvaTextTriangleStrokeDashed,
  canvaTextTriangleFilled,
  canvaTextTriangleFilledAndStroke,
  canvaTextCircleStrokeDashed,
  canvaTextCircleStrokeAndStrokeDashed,
  canvaTextCircleStrokeAndStroke,
  canvaTextCircleFill,
  canvaTextCircleFillAndStrokeDashed,
  canvaTextCircleFillAndStroke,
  canvaTextCircleFillAndStrokeAndStroke,
  // Pointers
  pointerCircleSmall,
  pointerCircleMedium,
  pointerCircleLarge,
  pointerTriangleSmall,
  pointerTriangleLarge,
  roundedPoly,
} from "../utilities/design-canvas";
import { designConstants } from "../utilities/design-constants-mindmap";
import ShowElement from "./modal-element/ShowElement";
import { rootUrl, camelCaseToSnakeCase } from "../../helpers/utilities";
import { limitMindmap } from "../utilities/limits-mindmap";

// Redux actions
import { fetchData, clearData } from "./redux/actions/graphDataActions";
import { closeSelectedNode } from "./redux/actions/selectedNodeActions";
import { selectNode } from "./redux/actions/selectedNodeActions";
import {
  addNodeForLink,
  cleanNodeForLinks,
  createLink,
  destroyLink,
} from "./redux/actions/nodesForLinkActions";

class MindMapChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.fgRef = React.createRef();
  }

  searchForSelectedNodeInParams() {
    const queryParams = window.location.search;
    const urlParams = new URLSearchParams(queryParams);
    const selectedNodeId = urlParams.get("selectedNode");
    const activeNode = this.props.graphData.nodes.find(
      (node) => node.id === selectedNodeId
    );
    this.props.selectNode(activeNode, this.props.challengeId);
  }

  async componentDidMount() {
    await this.props.fetchData();
    await this.searchForSelectedNodeInParams();
    // Use to zoom when the Graph is loaded
    this.dezoom();
    if (document.getElementById("spinner-loading")) {
      document.getElementById("spinner-loading").remove();
    }
    document.querySelector(".opacity-0").style.opacity = 1;
  }

  dezoom() {
    this.fgRef.current.zoomToFit(1000, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    // Si on ajoute un nouveau noeud, on zoom dessus
    if (prevProps.graphData.nodes.length < this.props.graphData.nodes.length) {
      this.dezoom();
    }
  }

  // Pour l'instant ne se unmount pas, on a desactivé Turbolinks dans la navbar des challenges
  componentWillUnmount() {
    this.props.clearData();
    document.getElementById("react-graphData").insertAdjacentHTML(
      "afterend",
      `
    <div class="container-spinner" id='spinner-loading'>
      <div class="spinner-border text-white">
        <div class="sr-only">
        </div>
      </div>
      Your data are loading...
    </div>
    `
    );
  }

  render() {
    // Object styling in the canva
    const nodeCanvasObject = (node, ctx) => {
      // Design les balises
      designConstants(ctx);

      if (node.type === "Need") {
        switch (node.status) {
          case "Draft":
            return canvaTextTriangleStrokeDashed(node, ctx);
          case "Published":
            return canvaTextTriangleStroke(node, ctx);
          case "Selected":
            return canvaTextTriangleFilled(node, ctx);
          case "Strategic":
            return canvaTextTriangleFilledAndStroke(node, ctx);
        }
      } else if (node.type === "Idea") {
        switch (node.status) {
          case "Draft":
            return canvaTextCircleStrokeDashed(node, ctx);
          case "Published":
            return canvaTextCircleStrokeAndStrokeDashed(node, ctx);
          case "Selected":
            return canvaTextCircleStrokeAndStroke(node, ctx);
          case "Potential":
            return canvaTextCircleFill(node, ctx);
          case "Prototype":
            return canvaTextCircleFillAndStrokeDashed(node, ctx);
          case "Validated":
            return canvaTextCircleFillAndStroke(node, ctx);
          case "Launched":
            return canvaTextCircleFillAndStrokeAndStroke(node, ctx);
        }
      } else {
        return canvaTextroundedRectangleWithLines(node, ctx);
      }
    };

    const nodePointerAreaPaint = (node, color, ctx) => {
      // Pointer to select the objects in the canva
      ctx.fillStyle = color;
      const bckgDimensions = node.__bckgDimensions;
      if (bckgDimensions && node.type === "Idea") {
        switch (node.status) {
          case "Draft":
          case "Potential":
            return pointerCircleSmall(node, ctx);
          case "Published":
          case "Prototype":
          case "Validated":
            return pointerCircleMedium(node, ctx);
          case "Selected":
          case "Launched":
            return pointerCircleLarge(node, ctx);
        }
      } else if (bckgDimensions && node.type === "Need") {
        switch (node.status) {
          case "Draft":
          case "Published":
          case "Selected":
            return pointerTriangleSmall(node, ctx);
          case "Strategic":
            return pointerTriangleLarge(node, ctx);
        }
      } else if (bckgDimensions) {
        // Dessine le background du rectangle
        const radius = 5;
        const height = 50;
        const width = 30;
        const centerX = node.x - width / 2;
        const centerY = node.y - height / 2;
        // Dessine les contours du triangle
        ctx.beginPath();
        const points = [
          { x: centerX, y: centerY },
          { x: centerX + width, y: centerY },
          { x: centerX + width, y: centerY + height },
          { x: centerX, y: centerY + height },
        ];
        roundedPoly(points, radius, ctx);
        ctx.fill();
        ctx.closePath();
      }
    };

    // Close the openened element (need/idea) if the user click on antoher part of the App
    const handleBackgroundClick = () => {
      if (Object.keys(this.props.selectedNode).length !== 0) {
        this.props.closeSelectedNode();
      }
    };

    const handleNodeClick = (node) => {
      this.props.selectNode(node, this.props.challengeId);
    };

    // LINKS
    const handleLinkClick = (link) => {
      this.props.destroyLink(this.props.challengeId, link.id);
    };

    // Behaviour handlings on MindMap
    const handleDragEnd = (node, translate) => {
      if (
        node.x > Math.min(...limitMindmap) &&
        node.x < Math.max(...limitMindmap) &&
        node.y > Math.min(...limitMindmap) &&
        node.y < Math.max(...limitMindmap)
      ) {
        // Send new coordinate to database
        axios({
          method: "patch",
          url: `${rootUrl}/api/v1/challenges/${
            this.props.challengeId
          }/${camelCaseToSnakeCase(node.type)}s/${node.id}/update_coord`,
          data: {
            [camelCaseToSnakeCase(node.type)]: {
              x: node.x,
              y: node.y,
            },
          },
        })
          .then()
          .catch((error) => console.log(error));
      } else {
        node.x = node.x - translate.x;
        node.y = node.y - translate.y;
        alert(
          "You're not in the mindmap. Please drag the element inside the mindmap (delimited by the numbers)"
        );
      }
    };

    // Right click on node 2 times to create a link
    const handleRightClickNode = (node, event) => {
      event.preventDefault();
      // Si le noeud avait déjà été selectionné on l'enlève et on rétabli la couleur originale
      if (this.props.nodesForLink.includes(node)) {
        this.props.cleanNodeForLinks();
        if (node.color_old) {
          node.color = node.color_old;
        }
      } else {
        this.props.addNodeForLink(node);
        if (node.color_selected) {
          node.color = node.color_selected;
        }
      }
      if (
        this.props.nodesForLink.length == 2 &&
        !findExistingLinks(this.props.nodesForLink)
      ) {
        this.props.createLink(this.props.challengeId, this.props.nodesForLink);
      } else if (this.props.nodesForLink.length == 2) {
        this.props.destroyLink(
          this.props.challengeId,
          findExistingLinks(this.props.nodesForLink).id
        );
      }
    };

    const findExistingLinks = (nodesForLinks) => {
      const ids = nodesForLinks.map((link) => link.id);
      return this.props.graphData.links.find((link) => {
        return ids.includes(link.source.id) && ids.includes(link.target.id);
      });
    };

    return (
      <div className="opacity-0 mindmap-challenge">
        <div className="title-mindmap" id="title-challenge-mindmap">
          # {this.props.challengeTitle}
        </div>
        <ShowElement />
        <div className="btn-dezoom" onClick={() => this.dezoom()}></div>
        <ForceGraph2D
          // Design canvas objects
          nodeCanvasObject={nodeCanvasObject}
          nodePointerAreaPaint={nodePointerAreaPaint}
          linkWidth={2}
          linkAutoColorBy="#ffffff"
          // Zoom to all elements inside the canvas
          ref={this.fgRef}
          // Behaviours handling
          onNodeClick={handleNodeClick}
          onNodeDragEnd={handleDragEnd}
          onNodeRightClick={handleRightClickNode}
          onLinkClick={handleLinkClick}
          onBackgroundClick={handleBackgroundClick}
          // Prevent the elements from moving
          cooldownTicks={0}
          graphData={this.props.graphData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    graphData: state.graphData,
    selectedNode: state.selectedNode,
    nodesForLink: state.nodesForLink,
    challengeId: state.challengeId,
    challengeTitle: state.challengeTitle,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(fetchData()),
    clearData: () => dispatch(clearData()),
    closeSelectedNode: () => dispatch(closeSelectedNode()),
    selectNode: (node, challengeId) => dispatch(selectNode(node, challengeId)),
    addNodeForLink: (node) => dispatch(addNodeForLink(node)),
    cleanNodeForLinks: () => dispatch(cleanNodeForLinks()),
    createLink: (challengeId) => dispatch(createLink(challengeId)),
    destroyLink: (challengeId, linkId) =>
      dispatch(destroyLink(challengeId, linkId)),
  };
};

MindMapChallenge.propTypes = {
  searchForSelectedNodeInParams: PropTypes.func,

  // From Redux state/store
  challengeId: PropTypes.string,
  challengeTitle: PropTypes.string,
  graphData: PropTypes.object,
  selectedNode: PropTypes.object,
  nodesForLink: PropTypes.array,
  cleanNodeForLinks: PropTypes.func,
  destroyLink: PropTypes.func,
  clearData: PropTypes.func,
  selectNode: PropTypes.func,
  addNodeForLink: PropTypes.func,
  createLink: PropTypes.func,
  fetchData: PropTypes.func,
  closeSelectedNode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MindMapChallenge);
