import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {connect} from 'react-redux';
import { moderateComment, deleteComment } from '../redux/actions/selectedNodeActions'

import {rootUrl} from '../../../helpers/utilities';

const Comment = ({content, date, author, authorId, id, moderated, avatar,
    // From Redux state/store
    currentUser,
    token,
    challengeId,
    moderateComment,
    deleteComment
}) => {
  const scrollLastCommentIntoView = () => {
    const comments = document.querySelectorAll('.card-comment');
    const lastComment = comments[comments.length - 1];
    if (lastComment !== undefined) {
      lastComment.scrollIntoView();
    }
  };

  useEffect(()=> scrollLastCommentIntoView(), [])


  const handleModerateComment = (comment_id) => {
    if (window.confirm('Are you sure you want to hide this comment ?')) {
      axios({
        url: `${rootUrl}/comments/${comment_id}/moderate`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        method: 'patch',
        data: {
          challenge_id: challengeId
        }
      })
      .then((comment) => {
        moderateComment(comment.data)
      })
      .catch(error => console.log(error))
    }
  }

  const handleDeleteComment = (comment_id) => {
    if (window.confirm('Are you sure you want to delete this comment ?')) {
      axios({
        url: `${rootUrl}/comments/${comment_id}`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        method: 'delete',
        data: {
          challenge_id: challengeId
        }
      })
      .then((comment) => {
        deleteComment(comment.data)
      })
      .catch(error => console.log(error))
    }
  }

  const renderModerate = () => {
    if(currentUser.admin) {
      return(<i className="fas fa-eye-slash icon comment-moderate" onClick={() => handleModerateComment(id)}></i>)
    } else {
      return null
    }
  }

  const renderDelete = () => {
    if(currentUser.id === authorId) {
      return(<i className="fas fa-trash-alt icon comment-delete" onClick={() => handleDeleteComment(id)}></i>)
    } else {
      return null
    }
  }

  const renderAvatar = () => {
    if(avatar) {
      return (
        <div className="very-small-avatar">
          <a href={`${rootUrl}/profiles/${authorId}`}>
            <img src={`${avatar.url}`}/>
          </a>
        </div>
        )
    } else {
      return (
        <a href={`${rootUrl}/profiles/${authorId}`}>
          <div className="very-small-avatar-default">
          </div>
        </a>

      )
    }
  }

  // RETURN
  if(moderated && authorId === currentUser.id) {
    return (
      <div className='card-comment author-comment d-flex margin-left-s'>
        <div className= 'card-avatar'>
          {renderAvatar()}
        </div>
        <div className='comment-content'>
          <div className='comment-details'>
            <div className='comment-date'>
              {date}
            </div>
          </div>
          <div className='comment-content-text moderated-comment small-text'>
            Your comment was hidden by a moderator
          </div>
        </div>
      </div>
    )
  }
  else if (moderated && authorId !== currentUser.id) {
    return (
      <div className='card-comment ext-comment margin-left-s'>
        <div className= 'card-avatar'>
          {renderAvatar()}
        </div>
        <div className='comment-content'>
          <div className='comment-details'>
            <div className='comment-date'>
              {date}
            </div>
            <div className='comment-author'>
              {author}
            </div>
          </div>
          <div className='comment-content-text moderated-comment small-text'>
            This comment was hidden by a moderator
          </div>
        </div>
      </div>
    )
  }
  else if(authorId === currentUser.id) {
    return (
      <div className='card-comment author-comment d-flex margin-left-s'>
        <div className= 'card-avatar'>
          {renderAvatar()}
        </div>
        <div className="comment-details">
          <div className="comment-author">
            {author}
          </div>
          <div className="comment-date">
            {date}
          </div>
        </div>
        <div className='comment-content'>
          <div className='comment-content-text small-text' >
            {content}
          </div>
        </div>
        <div className="comment-moderate-container mr-1">
          {renderModerate()}
          {renderDelete()}
        </div>
      </div>
    )
  } else {
    return (
      <div className='card-comment d-flex ext-comment margin-left-s'>
        <div className= 'card-avatar'>
          {renderAvatar()}
        </div>
        <div className="comment-details">
          <div className="comment-author">
            {author}
          </div>
          <div className="comment-date">
            {date}
          </div>
        </div>
        <div className='comment-content'>
          <div className='comment-content-text small-text'>
            {content}
          </div>
        </div>
        <div className="d-flex comment-moderate-container mr-1">
          {renderModerate()}
          {renderDelete()}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    moderateComment: (comment) => dispatch(moderateComment(comment)),
    deleteComment: (comment) => dispatch(deleteComment(comment))
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    token: state.token,
    challengeId: state.challengeId
  }
}

Comment.propTypes = {
  content: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  authorId: PropTypes.string,
  currentUser: PropTypes.object,
  id: PropTypes.number,
  moderated: PropTypes.bool,
  token: PropTypes.string,
  avatar: PropTypes.object,
  challengeId: PropTypes.string,
  moderateComment: PropTypes.func,
  deleteComment: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment)

