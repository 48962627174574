import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Task from './Task'

const Potential = ({
  // Redux
  selectedNode
}) => {

  return (
    <>
      <i className="fas fa-sort-up task-title-arrow task-title-arrow"></i>
      <div className="task-title">Potential</div>
      <div className='container-task'>
      <div className="row d-flex justify-content-center task-row">
        <Task 
          title="Drawing" attr='drawing' 
          status={selectedNode.progress.drawing_status} 
          user_name={selectedNode.progress.drawing_user.name} />
        <Task 
          title="Team" attr='team_identified' 
          status={selectedNode.progress.team_identified_status} 
          user_name={selectedNode.progress.team_identified_user.name} />
        <Task 
          title="Pitch" attr='pitch' 
          status={selectedNode.progress.pitch_status} 
          user_name={selectedNode.progress.pitch_user.name} />
        <Task 
          title="Presentation" attr='powerpoint_presentation' 
          status={selectedNode.progress.powerpoint_presentation_status} 
          user_name={selectedNode.progress.powerpoint_presentation_user.name} />
      </div> 
      <div className="row d-flex justify-content-center task-row">
        <Task 
          title="Feasability" attr='feasability_study' 
          status={selectedNode.progress.feasability_study_status} 
          user_name={selectedNode.progress.feasability_study_user.name} />
        <Task 
          title="Expected benefits" attr='expected_benefits' 
          status={selectedNode.progress.expected_benefits_status} 
          user_name={selectedNode.progress.expected_benefits_user.name} />
        <Task 
          title="Go from authority" attr='go_from_authority_potential' 
          status={selectedNode.progress.go_from_authority_potential_status} 
          user_name={selectedNode.progress.go_from_authority_potential_user.name} />
      </div>       
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode
  }
} 

Potential.propTypes = {
  selectedNode: PropTypes.object,
}

export default connect(mapStateToProps, null)(Potential)
