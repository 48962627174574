import React from 'react';
import PropTypes from 'prop-types';

import Validated from './Progress/Validated';
import Prototype from './Progress/Prototype';
import Potential from './Progress/Potential';


const TabProgress = ({
  // Own props
  activeTab
}) => {

  const renderContent = () => {
    if(activeTab) {
      return (
        <div>
          <div className="task-title">Launched</div>
          <Validated />
          <Prototype />
          <Potential />
        </div>
      )
    }
  }
  return (
    <>
      {renderContent()}
    </>
  )
}

TabProgress.propTypes = {
  activeTab: PropTypes.bool,
}



export default TabProgress
