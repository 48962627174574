import React from 'react';
import PropTypes from 'prop-types';

const InputAuthor = ({formik}) => {
  return (
    <div>
      <label htmlFor='author'>Author</label>
      <input
        id='author'
        name='author'
        type="textarea"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.author}
        className = {formik.touched.author && formik.errors.author ? "form-control is-invalid" : "form-control"}
      />
      {formik.touched.author && formik.errors.author ? <div className="alert-form">{formik.errors.author}</div> : null}
    </div>
  )
}


InputAuthor.propTypes = {
  formik: PropTypes.object
}

export default InputAuthor
