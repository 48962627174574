import consumer from "./consumer";
import { rootUrl } from "../helpers/utilities";
import axios from 'axios';

const initNotificationCable = () => {
  consumer.subscriptions.create({ channel: "NotificationChannel" }, {
    async received(data) {
      const notifIcon = document.getElementById('display-notif')
      const dropdownMenu = document.getElementById('dropdown-menu-notif')
      if (notifIcon) {
        const userId = notifIcon.dataset.user_id
        const cardShow = document.querySelector('.card-show-element')
        if (data.type === "notificable_node" && userId === data.notificable_user_id) {
          // If the user is the one who received a comment / like
          if (cardShow) {
            // If there is a Show Card open
            const idCardShow = cardShow.dataset.id
            if (data.notificable_id === idCardShow && data.open)
              // If the Show Card is the one concerned by the notification and is opened (we don't display notification if user is already on the card)
            {
              notifIcon.innerHTML = renderNotifications(data.notifs.length)
              dropdownMenu.innerHTML = renderNotificationsText(data.notifs, data.company_alias)
            }
          } else {
            notifIcon.innerHTML = renderNotifications(data.notifs.length)
            dropdownMenu.innerHTML = renderNotificationsText(data.notifs, data.company_alias)
          }
        } else if (data.type === "message") {
          const chatroom = document.getElementById('messages')
          if (userId === data.user_to_noticed_id) {
            if (chatroom) {
              const chatroomId = chatroom.dataset.chatroomId
              if (data.chatroom_id === chatroomId) {
                const token = chatroom.dataset.token
                // If the user who received the message is on the same page, notif need to be update to be seen
                await axios({
                  url: `${rootUrl}/update_notifs`,
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token
                  },
                  data: {
                    notificable_id: chatroomId,
                    receiver_id: userId,
                    type_notification: "chatroom"
                  },
                  method: 'patch',
                })
                .then()
                .catch(error => console.log(error))
              } else {
                notifIcon.innerHTML = renderNotifications(data.notifs.length)
                dropdownMenu.innerHTML = renderNotificationsText(data.notifs, data.company_alias)
              }
            } else {
              notifIcon.innerHTML = renderNotifications(data.notifs.length)
              dropdownMenu.innerHTML = renderNotificationsText(data.notifs, data.company_alias)
            }
          }
        }
      }
    }
  });
}

const renderNotifications = (numberNotifs) => {
  if (numberNotifs === 0) {
    return null
  } else {
    return `
    <div class="notif-circle" >${numberNotifs}</div>
  `
  }
}

const renderNotificationsText = (notifications, company) => {
  let text_rendered = '';
  notifications.forEach((notification) => {
    // Each comment / like
    // obj = notifications pour chacun des types de notifications
    text_rendered += textDropDown(notification, company)
  })
 return text_rendered
}

const textDropDown = (notification) => {
    return `
    <a class="dropdown-item" href="${notification.path}">
      <i class="${notification.icon}"></i> ${notification.text}
    </a>
    `
}

export { initNotificationCable };