import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cleanLinkedNodeActive } from '../../redux/actions/linkedNodeActiveActions';
import { updateNotificable } from '../../redux/actions/notificationActions';

import TabInfos from '../Tabs/TabInfos';
import TabComments from '../Tabs/TabComments';

const LinkedNode = ({
  // Own Props
  node,

  // Redux
  cleanLinkedNodeActive,
  currentUser,
  token,
}) =>
{
  useEffect(() => {
    // We update each time there is a modification on the node
    updateNotificable(node, token, currentUser)
  });

  
  const [activeTab, setActiveTab] = useState('Project');

  const clickTab = (event) => {
    setActiveTab(event.currentTarget.innerText)
  }

  return (
    <div style={{height: '100%'}} className='d-flex justify-content-between flex-column'>
      <div style={{height: '100%'}}>
        <div className="close" onClick={cleanLinkedNodeActive}>&times;</div>
        {node.name}
        <TabInfos activeTab={activeTab === 'Project'} node={node}/>
        <TabComments activeTab={activeTab === 'Comments'} node={node} linked={true}/>
      </div>
      <div className="d-flex">
        <div className={activeTab === 'Project' ? 'card-tab active' : 'card-tab'} onClick={clickTab}>Project</div>
        <div className={activeTab === 'Comments' ? 'card-tab active' : 'card-tab'} onClick={clickTab}>Comments</div>
      </div>
    </div>
  )
}

const mapStateToProps = ({currentUser, token}) => {
  return {
    token,
    currentUser
  }
}

const dispatchStateToProps = (dispatch) => {
  return {
    cleanLinkedNodeActive: () => dispatch(cleanLinkedNodeActive())
  }
}

LinkedNode.propTypes = {
  node: PropTypes.object,
  cleanLinkedNodeActive: PropTypes.func,
  token: PropTypes.string,
  currentUser: PropTypes.object
}

export default connect(mapStateToProps, dispatchStateToProps)(LinkedNode)
