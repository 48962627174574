import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {rootUrl} from '../../../helpers/utilities';

import {destroyVideo} from '../redux/actions/selectedNodeActions';

const InputVideo = ({selectedNode, destroyVideo}) => {

  const [video, setVideo] = useState(selectedNode.video)

  useEffect(() => {
    setVideo(selectedNode.video)
  }, [selectedNode])
  
  const handleDeleteVideo = (id) => {
    destroyVideo(id);
  }
  
  const renderVideo = () => {
    // Return the Video + option to destroy || Return the Input to add a video
    if(video) {
      return(
        <>
          <video controls className='card-element-video'>
            <source src={video.url} type={video.type} />
            <p>Votre navigateur ne prend pas en charge les vidéos HTML5.
              Voici <a href={video.url}> un lien pour télécharger la vidéo</a>.</p>
          </video>
          <div className="link" onClick={() => handleDeleteVideo(video.id)}>Destroy</div>
          <input type="hidden" id={`video`} defaultValue={video.key}/>
        </>
      )
    } else {
      return(
        <div 
          className="dropzone dropzone-default dz-clickable" 
          data-controller="dropzone" 
          data-dropzone-max-file-size="15" 
          data-dropzone-max-files="1" 
          data-dropzone-accepted-files="video/*"
        >
          <div className={`form-group file optional video`}>
            <label className={`file optional`} htmlFor="video">video</label>
            <input 
              data-direct-upload-url={`${rootUrl}/rails/active_storage/direct_uploads`}
              className="form-control-file file optional" 
              type="file" 
              name={`video`} 
              id={`video`} 
              data-dropzone-target="input"
              disabled="" 
              style={{display: 'none'}} />
          </div>
          <div className="dz-default dz-message">
            <button className="dz-button" type="button">Drop files here to upload or click to browse</button>
          </div>
          <span className="dropzone-msg-desc text-sm">
            <p m='0'>Must be a video format</p>
            <p m='0'>Max size : 5 MB</p>
          </span>
        </div>
      )
    }
  }
  
  return (
    renderVideo()
  )
}

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyVideo: (id) => dispatch(destroyVideo(id)),
  }
}


InputVideo.propTypes = {
  // From Redux
  destroyVideo: PropTypes.func,
  selectedNode: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputVideo);
