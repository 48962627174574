import 'slick-carousel';

const slickCarousel = () => {
  const slickCarouselSelector = (selector, arrowSuffix = "", autoPlay = true) => {
    // Si le selector existe et que le carousel n'a pas encore été initialisé
    if($(selector) && !$(selector+'.slick-initialized')[0]) {
      $(selector).slick({
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: autoPlay,
        variableWidth: false,
        arrows: true,
        autoplaySpeed: 2000,
        prevArrow: $(`.prev-arrow${arrowSuffix}`),
        nextArrow: $(`.next-arrow${arrowSuffix}`),
        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true,
              autoplay: autoPlay,
              variableWidth: false,
              arrows: true,
              autoplaySpeed: 2000,
              prevArrow: $(`.prev-arrow${arrowSuffix}`),
              nextArrow: $(`.next-arrow${arrowSuffix}`),
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay: autoPlay,
              variableWidth: false,
              arrows: true,
              autoplaySpeed: 2000,
              prevArrow: $(`.prev-arrow${arrowSuffix}`),
              nextArrow: $(`.next-arrow${arrowSuffix}`),
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay: autoPlay,
              variableWidth: false,
              arrows: true,
              autoplaySpeed: 2000,
              prevArrow: $(`.prev-arrow${arrowSuffix}`),
              nextArrow: $(`.next-arrow${arrowSuffix}`),
              swipe: true,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              autoplay: autoPlay,
              variableWidth: false,
              arrows: true,
              autoplaySpeed: 2000,
              prevArrow: $(`.prev-arrow${arrowSuffix}`),
              nextArrow: $(`.next-arrow${arrowSuffix}`),
              swipe: true,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
    }
  }

  slickCarouselSelector('.carousel-slick')
  slickCarouselSelector('.carousel-slick-knowledge', '-knowledge', false)


  // Test pour chaque category, on récupère le nom de chaque categorie basée sur la classe CSS (ex: carousel-slick-category-social)
  if(document.querySelectorAll("div[class*='carousel-slick-category']")) {
    const categories = document.querySelectorAll("div[class*='carousel-slick-category']")
    categories.forEach(cat => {
      // Extract the category name from the class
      const catName = cat.className.replace('carousel-slick-category-', '')
      slickCarouselSelector(`.carousel-slick-category-${catName}`,  `-knowledge-${catName}`, false)
    })
  }
};

export {slickCarousel}
