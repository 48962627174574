import React, { useState, useEffect, useRef } from 'react'
import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';
import axios from 'axios';
import PropTypes from 'prop-types';

import { canvaTextRect } from '../utilities/design-canvas';
import {zoomOnChallenges } from '../utilities/zoomchallenges';

import {rootUrl} from '../../helpers/utilities';

const MindMapCarousel = ({universeId, universeTitle}) => {

  // Used for the zoom at the start
  const fgRef = useRef();

  // Variables
  const [graphData, setGraphData] = useState({nodes: [], links: []})

  // Object styling in the canva
  const nodeCanvasObject = (node, ctx, globalScale) => {
    canvaTextRect(node, ctx, globalScale)
  }

  // Functions fetching the API
  const fetchChallenges = async () => {
    const nodesData = await axios({
      url: `${rootUrl}/api/v1/universes/${universeId}/challenges`,
      method: 'get',
    }).then(response => response.data)
    return nodesData
  }

  const handleClick = () => {
    window.location = `${rootUrl}/universes/${universeId}`
  }

  const getWidthOfContainer = () => {
    if(document.querySelector('.slider-wrapper')) {
      return document.querySelector('.slider-wrapper').offsetWidth
    }
  }

  // Use Effects
  useEffect(() => {
    const asyncFunction = async () => {
      const nodes = await fetchChallenges()
      setGraphData({nodes: nodes, links: []})
      zoomOnChallenges(nodes, fgRef)
      document.querySelector('.opacity-0').style.opacity = 1
      const spinner = document.getElementById('spinner-loading')
      if (spinner){
        spinner.remove()
      }
    }
   asyncFunction();
  }, [])

  return (
    <div className='canva-univers' onClick={handleClick}>
      <div className="title-mindmap" id="title-mindmap-top">
        {universeTitle}
      </div>
      <ForceGraph2D
        // Design canvas objects
        nodeCanvasObject={nodeCanvasObject}
        width={getWidthOfContainer()}

        // Zoom to all elements inside the canvas
        ref={fgRef}
        onEngineStop={() => zoomOnChallenges(graphData.nodes, fgRef)}
        // Disable zoom
        // Can't drag Node in this MindMap
        enableNodeDrag={false}
        // Hide labels
        nodeLabel={() => ''}

        // Prevent the elements from moving
        cooldownTicks={0}

        // nodePointerAreaPaint={nodePointerAreaPaint}
        graphData={graphData}
      />
    </div>
  )
}

MindMapCarousel.propTypes = {
  universeId: PropTypes.number,
  universeTitle: PropTypes.string,
};

export default MindMapCarousel
