import axios from 'axios';

export const nodeStatus = ['Draft', 'Published', 'Selected', 'Strategic', 'Potential', 'Prototype', 'Validated', 'Launched']

const maybePluralize = (count, noun, suffix = 's') => {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

const capitalize = word => word.replace(/^\w/, (c) => c.toUpperCase())

const aOrAn = word => ['a','e','i','o','u','y'].includes(word[0]) ? 'an' : 'a'

const rootUrl = window.location.origin;

const getMetaValue = (name) => {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if(element) {
    return element.getAttribute('content');
  }
}

const findElement = (root, selector) => {
  if(typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

const toArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

const removeElement = (el) => {
  if (el && el.parentNode) {
    try {
      el.parentNode.removeChild(el);
      axios({
        url: `${rootUrl}/destroy_annexe/${el.dataset.publicid}`,
        headers: {
          'X-CSRF-Token': getMetaValue('csrf-token')
        },
        method: 'delete'
      })
    } catch (error) {
      console.log(error)
    }
  }
}

const insertAfter = (el, referenceNode) => {
  return referenceNode.parentNode.insertBefore(el, referenceNode)
}

const camelCaseToSnakeCase = (str) => {
  return str.split(/(?=[A-Z])/).join('_').toLowerCase();
}

const getUserName = (user) => {
  if(user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`
  } else {
    return user.email
  }
}


export { maybePluralize, capitalize, aOrAn, rootUrl, insertAfter, toArray, removeElement, findElement, getMetaValue, camelCaseToSnakeCase, getUserName};