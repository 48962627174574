import {
  GET_STATUS_TASK_ACTIVE
} from './actionsTypes';


export const getStatusTaskActive = (status) => {
  return {
    type: GET_STATUS_TASK_ACTIVE,
    payload: status
  }
}