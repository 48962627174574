import {SET_LINKED_NODE_ACTIVE, CLEAN_LINKED_NODE_ACTIVE} from '../actions/actionsTypes';

export default function(state = {}, action) {
  switch(action.type) {
    case SET_LINKED_NODE_ACTIVE:
      return {...action.payload}
    case CLEAN_LINKED_NODE_ACTIVE:
      return {...action.payload}
    default: return state
  }
}