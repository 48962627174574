import axios from 'axios';
import {rootUrl} from '../../../../helpers/utilities';

export const updateNotificable = (node, token, currentUser) => {
  if(currentUser.id == node.user.id){
    if (node.notifications.length > 0){
      axios({
        url: `${rootUrl}/update_notifs`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        data: {
          notificable_id: node.id,
          notificable_type: node.type,
          notificable_user_id: node.user.id,
          open: true,
          type_notification: "node"
        },
        method: 'patch',
      })
      .then(() => node.notifications = [])
      .catch(error => console.log(error))
    }
    const unseenComments = node.comments.filter(comment => comment.seen === false).map((comment) => comment.id)
    if(unseenComments.length > 0){
      axios({
        url: `${rootUrl}/comments`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        data: {
          commentable_id: node.id,
          commentable_type: node.type
        },
        method: 'patch',
      })
      .then()
      .catch(error => console.log(error))
    }
    const unseenLikes = node.likes.filter(like => like.seen === false).map((like) => like.id)
    if(unseenLikes.length > 0){
      axios({
        url: `${rootUrl}/likes`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        data: {
          likable_id: node.id,
          likable_type: node.type
        },
        method: 'patch',
      })
      .then()
      .catch(error => console.log(error))
    }
  }
}