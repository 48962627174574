import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleAddTeam } from '../../redux/actions/selectedNodeActions';


import {rootUrl} from '../../../../helpers/utilities';

const TabTeam = ({
  // Own Props
  activeTab,
  // Redux state
  selectedNode,
  handleAddTeam
}) => {

  const renderAvatar = (user) => {
    if(user.avatar) {
      return(
        <div className="very-small-avatar mr-1" >
          <img src={`${user.avatar.url}`}/>
        </div>
      )
    } else {
      <div className="very-small-avatar-default mr-1" ></div>
    }
  }

  const renderUser = (user) => {
    return(
      <a href={`${rootUrl}/profiles/${user.id}`} className="d-flex align-items-center"> 
        {renderAvatar(user)}
        <div className='user-name-team'>
          {user.name}
        </div>
      </a>
    )
  }

  // ACCPETED
  const renderTeamLeaders = () => {
    return selectedNode.team.filter(team => team.status == "accepted" && team.role == "team_leader").map(team => {
      return(
        <div className='small-text section-text-enlight mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div> 
      )
    })
  }

  const renderKeySpecialists = () => {
    return selectedNode.team.filter(team => team.status == "accepted" && team.role == "key_specialist").map(team => {
      return(
        <div className='small-text mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div> 
      )
    })
  }

  const renderTeamMembers = () => {
    return selectedNode.team.filter(team => team.status == "accepted" && team.role == "team_member").map(team => {
      return(
        <div className='small-text mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div> 
      )
    })
  }

  // PENDING
  const renderPendingTeamLeaders = () => {
    return selectedNode.team.filter(team => team.status == "pending" && team.role == "team_leader").map(team => {
      return(
        <div className='small-text section-text-white mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div>
      )
    })
  }

  const renderPendingKeySpecialists = () => {
    return selectedNode.team.filter(team => team.status == "pending" && team.role == "key_specialist").map(team => {
      return(
        <div className='small-text section-text-white mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div>
      )
    })
  }

  const renderPendingTeamMembers = () => {
    return selectedNode.team.filter(team => team.status == "pending" && team.role == "team_member").map(team => {
      return(
        <div className='small-text section-text-white mb-1' key={team.user.id}>
          {renderUser(team.user)}
        </div>
      )
    })
  }

  const renderContent = () => {
    if(activeTab) {
      return (<div className="container-card-show">
        <div className="d-flex align-items-center container-team-gnl my-1"> 
          <div className="text-title-font title-14 section-text-white text-title-font d-flex align-items-center card-team">
            <div id="teamleader-title-icon"></div>
            Team Leaders
          </div>
          <div className="card-team">
            <button className="button-team-leader" onClick={() => handleAddTeam({role: "team_leader"})}>Candidate as Team Leader</button>
          </div>
        </div>
        <div className="d-flex container-team container-team-gnl">
          <div className="card-team">
            {renderTeamLeaders()}
          </div>
          <div className="card-team small-text section-text-white  text-title-font">
            <div className="small-text section-text-white">/ Candidates</div>
            {renderPendingTeamLeaders()}
          </div>
        </div>
        <div className="d-flex align-items-center container-team-gnl my-1">
          <div className="text-title-font title-14 section-text-white text-title-font d-flex align-items-center card-team">
            Key Specialists
          </div>
          <div className="card-team">
            <button className="button-team-leader" onClick={() => handleAddTeam({role: "key_specialist"})}>Participate as Key Specialist</button>
          </div>
        </div>
        <div className="d-flex container-team container-team-gnl"  id="container-team-specialists">
          <div className="card-team">
            {renderKeySpecialists()}
          </div>
          <div className="card-team small-text section-text-white  text-title-font">
            <div className="small-text section-text-white">/ Candidates</div>
            {renderPendingKeySpecialists()}
          </div>
        </div>
        <div className="d-flex align-items-center container-team-gnl my-1">
          <div className="text-title-font title-14 section-text-white text-title-font d-flex align-items-center card-team">
          Team member
          </div>
          <div className="card-team">
            <button className="button-team-leader" onClick={() => handleAddTeam({role: "team_member"})}>Participate as Team member</button>
          </div>
        </div>
        <div className="d-flex container-team container-team-gnl">
          <div className="card-team">
            {renderTeamMembers()}
          </div>
          <div className="card-team small-text section-text-white  text-title-font">
            <div className="small-text section-text-white">/ Candidates</div>
            {renderPendingTeamMembers()}
          </div>
        </div>
      </div>)
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

const mapStateToProps = state => {
  return {
    selectedNode: state.selectedNode
  }
}

const mapDispatchToProps = (disptach) => {
  return {
    handleAddTeam: (role) => disptach(handleAddTeam(role))
  }
} 

TabTeam.propTypes = {
  activeTab: PropTypes.bool,
  selectedNode: PropTypes.object,
  handleAddTeam: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(TabTeam)

