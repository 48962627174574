export const zoomOnChallenges = (nodes, ref) => {
  // We get the distance between the most distant x and y point
  const widthDiff = calcDiffGraph(nodes.map((node) => node.x))
  const heightDiff = calcDiffGraph(nodes.map((node) => node.y))
  // We get the size of the canvas
  const canvaWidth = parseInt(document.querySelector('canvas').style.width);
  const canvaHeight = parseInt(document.querySelector('canvas').style.height);

  const minSideSize = Math.min(canvaWidth, canvaHeight);
  const minDiff = Math.min(widthDiff, heightDiff);

  let zoomAlone
  if(nodes.length === 1) zoomAlone = minSideSize / 2.1

  const zoomPadding = zoomAlone || (minSideSize - minDiff) / 3 ;
  ref.current.zoomToFit(1000, zoomPadding)
}

const calcDiffGraph = (array) => {
  return Math.abs(Math.max(...array) - Math.min(...array))
}
