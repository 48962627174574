// THIS IS THE COMPONENT TO "LINK" THE REDUX STATE/STORE TO THE COMPONENT REACT (Has to be on lvl upward)

import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import store from './redux/store';
import App from './App';

const RootContainer = ({
  currentUser,
  challengeId,
  challengeTitle,
  token,
  status,
  statusSolution,
  knowledgeCategories,
  statusTaskActive,
  statusTaskInactive
}) => {

  return (
    <Provider store={store}>
      <App
        currentUser = {currentUser}
        challengeId = {challengeId}
        challengeTitle = {challengeTitle}
        token = {token}
        status={status}
        statusSolution={statusSolution}
        knowledgeCategories={knowledgeCategories}
        statusTaskActive={statusTaskActive}
        statusTaskInactive={statusTaskInactive}
      />
    </Provider>
  )
}

RootContainer.propTypes = {
  status: PropTypes.object,
  knowledgeCategories: PropTypes.array,
  currentUser: PropTypes.object,
  challengeId: PropTypes.string,
  challengeTitle: PropTypes.string,
  token: PropTypes.string,
  statusSolution: PropTypes.array,
  statusTaskActive: PropTypes.array,
  statusTaskInactive: PropTypes.array
};


export default RootContainer
