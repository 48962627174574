import React from 'react';
import PropTypes from 'prop-types';

const InputCorporate = ({formik}) => {
  return (
    <div>
      <label htmlFor='corporate'>corporate</label>
      <input
        id='corporate'
        name='corporate'
        type="checkbox"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.corporate}
      />
      {formik.touched.corporate && formik.errors.corporate ? <div className="alert-form">{formik.errors.corporate}</div> : null}
    </div>
  )
}

InputCorporate.propTypes = {
  formik: PropTypes.object
}

export default InputCorporate
