import React from 'react'
import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';
import axios from 'axios'
import PropTypes from 'prop-types';

import consumer from "../../channels/consumer";

import {zoomOnChallenges } from '../utilities/zoomchallenges';
import { canvaTextRect } from '../utilities/design-canvas';
import {designConstants} from '../utilities/design-constants-mindmap';
import {limitMindmap} from '../utilities/limits-mindmap';

import { rootUrl } from '../../helpers/utilities';

class MindMapUniverse extends React.Component {
  constructor(props) {
    super(props);
    // Used for the zoom at the start
    this.myRef = React.createRef();
    this.state = {
      graphData: {nodes: [], links: []},
    }
  }

  async componentDidMount() {
    const nodes = await this.fetchChallenges()

    this.setState({
      graphData: {nodes: nodes, links: []}
    })
    // Use to zoom when the Graph is loaded
    zoomOnChallenges(this.state.graphData.nodes, this.myRef)
    document.querySelector('.opacity-0').style.opacity = 1
    document.getElementById('spinner-loading').remove()

    // action cable for comments (app/channel/comment_channel)
    consumer.subscriptions.create(
      { channel: "ChallengeChannel", id: this.props.universeId},
      {
        received: data => {
          // Si l'action qui arrive est le déplacement d'un noeud
          if(data.nodeMoved) {
            const newNode = data.nodeMoved
            const oldNodes = this.state.graphData.nodes
            const newNodes = oldNodes.map(oldNode => {
            // Verify ID to actualize Graph Data
              if(oldNode.id === newNode.id) {
                return newNode
              } else {
                return oldNode
              }
            });
            this.setState({
              graphData: {nodes: newNodes, links: []}
            });
          }
        }
      }
    )
  }

  componentWillUnmount() {
    this.setState({
      graphData: {nodes: [], links: []}
    });
    document.getElementById('react-graphData').insertAdjacentHTML('afterend',
    `
    <div class="container-spinner" id='spinner-loading'>
      <div class="spinner-border text-white">
        <div class="sr-only">
        </div>
      </div>
      Your data are loading...
    </div>
    `)
  }

  // Functions fetching the API
  fetchChallenges = async () => {
    const nodesData = await axios({
      url: `${rootUrl}/api/v1/universes/${this.props.universeId}/challenges`,
      method: 'get',
    })
    .then(response => response.data)
    .catch(error => console.log(error))
    return nodesData
  }

  handleNodeClick = (node) => {
    window.location = `${rootUrl}/challenges/${node.id}`
  }

  // Handling Behaviours
  handleDragEnd = (node, translate) => {
    if(node.x > Math.min(...limitMindmap) && node.x < Math.max(...limitMindmap) && node.y > Math.min(...limitMindmap) && node.y < Math.max(...limitMindmap)) {
      // Send new coordinate to database
      axios({
        method: 'patch',
        url: `${rootUrl}/api/v1/challenges/${node.id}/update_coord`,
        data: {
          challenge: {
            x: node.x,
            y: node.y,
          },
          universeId: this.props.universeId
        }
      })
      .then()
      .catch(error => console.log(error))
    } else {
      node.x = node.x - translate.x;
      node.y = node.y - translate.y;
      alert("You're not in the mindmap. Please drag the element inside the mindmap (delimited by the numbers)")
    }
  }

  render() {
    // Object styling in the canva
    const nodeCanvasObject = (node, ctx, globalScale) => {
      // Design les balises
      designConstants(ctx)

      canvaTextRect(node, ctx, globalScale)
    }

    const nodePointerAreaPaint=(node, color, ctx) => {
      ctx.fillStyle = color;
      const height = 50
      const width = 100
      const radius = 5
      const centerX = node.x - width / 2
      const centerY = node.y - height / 2
      // Dessine le rectangle
      ctx.beginPath();
      ctx.moveTo( centerX + radius, centerY);
      ctx.arcTo( centerX + width, centerY, centerX + width, centerY + height, radius);
      ctx.arcTo( centerX + width, centerY + height, centerX, centerY + height, radius);
      ctx.arcTo( centerX, centerY + height, centerX, centerY, radius);
      ctx.arcTo( centerX, centerY, centerX + width, centerY, radius);
      ctx.fill();
      ctx.closePath();
    }

    return (
      <div className="opacity-0 mindmap-opacity">
        <div className="title-mindmap" id="title-mindmap-top">
          {this.props.universeTitle}
        </div>
        <ForceGraph2D
          // Design canvas objects
          nodePointerAreaPaint={nodePointerAreaPaint}
          nodeCanvasObject={nodeCanvasObject}

          // Zoom to all elements inside the canvas
          ref={this.myRef}

          // Behaviours handling
          onNodeDragEnd = {this.handleDragEnd}

          // Action on canvas
          onNodeClick={this.handleNodeClick}

          // Prevent the elements from moving
          cooldownTicks={0}

          // nodePointerAreaPaint={nodePointerAreaPaint}
          graphData={this.state.graphData}
        />
      </div>
    )
  }
}

MindMapUniverse.propTypes = {
  universeId: PropTypes.number,
  universeTitle: PropTypes.string,
};

export default MindMapUniverse
