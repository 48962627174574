import React from 'react';
import PropTypes from 'prop-types';

const TabInfos = ({
  // OwnProps
  activeTab,
  node
}) => {

  // RENDERS

  // Render Thumnail
  const renderThumbnail = () => {
    if(node.thumbnail) {
      return(
        <div className="card-element-thumbnail">
          <img src={`${node.thumbnail.url}`} alt={`${node.thumbnail.name}`}/>
        </div>
      )
    } else {
      return null
    }
  }

  // Render documents
  const renderDocuments = () => {
    if(node.documents) {
      const documentsUrl = node.documents.map(document => {
        return (
          <li className="card-documents-links" key={document.url}>
            <a className="link button-document-element " target="_blank" rel='noreferrer' href={document.url}>
              <i className="fas fa-file-pdf icon-white-middle"></i>
            </a>
          </li>
        )
      })
      return(
        <div className="card-element-documents">
          {documentsUrl}
        </div>
      )
    } else if (node.document) {
      return (
        <>
          <a className="link btn-second button-document-element" target="_blank" rel='noreferrer' href={node.document.url}>{node.document.name}</a>
        </>
      )
    } else {
      return null
    }
  }

  // render Video
  const renderVideo = () => {
    if(node.video) {
      return (
        <video controls className='card-element-video'>
          <source src={node.video.url} type={node.video.type} />
          <p>Votre navigateur ne prend pas en charge les vidéos HTML5.
            Voici <a href={node.video.url}> un lien pour télécharger la vidéo</a>.</p>
        </video>
      )
    }
  }

  /// function  for rendering video or image
  const renderVideoOrImage = () => {
    if(node.video && node.thumbnail) {
      return (
        renderVideo()
      )
    } else if (node.video) {
      return (
        renderVideo()
      )
    } else if (node.thumbnail) {
      return (
        renderThumbnail()
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    if(activeTab) {
      return (
        <div className="container-card-show container-modal-infos">

          <div className='card-element-status'>
            {node.status}
          </div>
          <div className="d-flex w-100">
            {renderVideoOrImage()}
            <div className="">
              <div className="title-14 section-text-white mb-2">/ Attached Documents </div>
              {renderDocuments()}
            </div>
          </div>
          <div className='title-14 section-text-white mb-2'>/ Description</div>
          <div 
            className='card-element-content'
            dangerouslySetInnerHTML={{__html: node.content}}
          >
          </div>
        </div>
      )
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

TabInfos.propTypes = {
  activeTab: PropTypes.bool,
  node: PropTypes.object,
}

export default TabInfos;
