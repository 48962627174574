import {
  GET_STATUS
} from './actionsTypes';


export const getStatus = (status) => {
  return {
    type: GET_STATUS,
    payload: status
  }
}