import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { ADD_NODE_LINK, CLEAN_NODE_LINK } from "../actions/actionsTypes";
import { rootUrl } from "../../../../helpers/utilities";

// Functions fetching the API
export const addNodeForLink = (node) => {
  // Quand on selectionne le noeud on lui passe une nouvelle couleur violette
  node.color_selected = "#785CF5";
  node.color_old = node.color;
  return {
    type: ADD_NODE_LINK,
    payload: node,
  };
};

export const cleanNodeForLinks = (node) => {
  return {
    type: CLEAN_NODE_LINK,
    payload: [],
  };
};

export const createLink = (challengeId) => {
  return async (dispatch, getState) => {
    await axios({
      method: "post",
      url: `${rootUrl}/api/v1/challenges/${challengeId}/links`,
      data: {
        link: {
          source_id: getState().nodesForLink[0].id,
          source_type: getState().nodesForLink[0].type,
          target_id: getState().nodesForLink[1].id,
          target_type: getState().nodesForLink[1].type,
          challenge_id: challengeId,
        },
      },
    }).catch((error) => {
      // If the link could not be created, we reset the color of the selected Nodes
      _resetNodesColor(getState);

      _handleError(error);
    });
    dispatch({ type: CLEAN_NODE_LINK, payload: [] });
  };
};

export const destroyLink = (challengeId, linkId) => {
  return async (dispatch, getState) => {
    await axios({
      method: "delete",
      url: `${rootUrl}/api/v1/challenges/${challengeId}/links/${linkId}`,
    }).catch((error) => console.log(error));
    dispatch({ type: CLEAN_NODE_LINK, payload: [] });
  };
};

const _resetNodesColor = (getState) => {
  getState()
    .graphData.nodes.filter((node) =>
      getState()
        .nodesForLink.map((node) => node.id)
        .includes(node.id)
    )
    .forEach((node) => (node.color = node.color_old));
};

const _handleError = (error) => {
  if (
    error.response.status === 422 &&
    error.response.data.errors[0].match(
      /(Source|Target) Idea solution cannot be linked to another node/
    )
  ) {
    Swal.fire({
      title: "Forbidden!",
      text: `You can't link an idea whose status is Potential or over`,
      icon: "warning",
      confirmButtonText: "Understood",
    });
  }
};
