import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import consumer from "../../channels/consumer";
import MindMapChallenge from "./MindMapChallenge";
import ButtonAddNode from "./buttons/ButtonAddNode";

// Redux actions
import {
  renderLinks,
  renderNodes,
} from "./redux/actions/graphDataActions";
import { getCurrentUser } from "./redux/actions/currentUserActions";
import { getChallengeId } from "./redux/actions/challengeIdActions";
import { getChallengeTitle } from "./redux/actions/challengeTitleActions";
import { getToken } from "./redux/actions/tokenActions";
import { getStatus } from "./redux/actions/statusActions";
import { getStatusSolution } from "./redux/actions/statusSolutionActions";
import { getKnowledgeCategories } from "./redux/actions/knowledgeCategoriesActions";
import { getStatusTaskActive } from "./redux/actions/statusTaskActive";
import { getStatusTaskInactive } from "./redux/actions/statusTaskInactive";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
    };
  }

  componentDidMount() {
    // Setup constants in the redux store/state
    const fetchData = async () => {
      await this.props.getCurrentUser(this.props.currentUser);
      await this.props.getChallengeId(this.props.challengeId);
      await this.props.getToken(this.props.token);
      await this.props.getStatus(this.props.status);
      await this.props.getStatusSolution(this.props.statusSolution);
      await this.props.getKnowledgeCategories(this.props.knowledgeCategories);
      await this.props.getStatusTaskActive(this.props.statusTaskActive);
      await this.props.getStatusTaskInactive(this.props.statusTaskInactive);
      this.setState({ isDataLoaded: true });
    };
    fetchData();

    // Action cable for the nodes
    consumer.subscriptions.create(
      { channel: "NodeChannel", id: this.props.challengeId },
      {
        received: (data) => {
          this.props.renderNodes(
            data,
            this.props.graphData,
            this.props.currentUser
          );
        },
      }
    );
    // Active Action cable for the links (app/channel/link_channel)
    consumer.subscriptions.create(
      { channel: "LinkChannel", id: this.props.challengeId },
      {
        received: (data) => {
          this.props.renderLinks(data, this.props.graphData);
        },
      }
    );
  }

  render() {
    return (
      <>
        <div className="card-list-buttons">
          <ButtonAddNode node="need" />
          <ButtonAddNode node="idea" />
        </div>
        {this.state.isDataLoaded && <MindMapChallenge />}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    graphData: state.graphData,

    // Get the props passed through the RootContainer
    currentUser: ownProps.currentUser,
    challengeId: ownProps.challengeId,
    challengeTitle: ownProps.challengeTitle,
    token: ownProps.token,
    status: ownProps.status,
    statusSolution: ownProps.statusSolution,
    knowledgeCategories: ownProps.knowledgeCategories,
    statusTaskActive: ownProps.statusTaskActive,
    statusTaskInactive: ownProps.statusTaskInactive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    renderLinks: (data, graphData) => dispatch(renderLinks(data, graphData)),
    renderNodes: (data, graphData, user) =>
      dispatch(renderNodes(data, graphData, user)),
    // renderComments: (data, graphData) =>
    //   dispatch(renderComments(data, graphData)),
    // renderLikes: (data, graphData) => dispatch(renderLikes(data, graphData)),

    // Setup constants in the redux store/state
    getCurrentUser: (currentUser) => dispatch(getCurrentUser(currentUser)),
    getChallengeId: (challengeId) => dispatch(getChallengeId(challengeId)),
    getChallengeTitle: (challengeTitle) =>
      dispatch(getChallengeTitle(challengeTitle)),
    getToken: (token) => dispatch(getToken(token)),
    getStatus: (status) => dispatch(getStatus(status)),
    getStatusSolution: (statusSolution) =>
      dispatch(getStatusSolution(statusSolution)),
    getKnowledgeCategories: (knowledgeCategories) =>
      dispatch(getKnowledgeCategories(knowledgeCategories)),
    getStatusTaskActive: (statusTaskActive) =>
      dispatch(getStatusTaskActive(statusTaskActive)),
    getStatusTaskInactive: (statusTaskInactive) =>
      dispatch(getStatusTaskInactive(statusTaskInactive)),
  };
};

App.propTypes = {
  // Own props
  token: PropTypes.string,
  challengeId: PropTypes.string,
  challengeTitle: PropTypes.string,
  currentUser: PropTypes.object,
  knowledgeCategories: PropTypes.array,
  status: PropTypes.object,
  statusSolution: PropTypes.array,
  statusTaskActive: PropTypes.array,
  statusTaskInactive: PropTypes.array,

  // From redux
  renderLinks: PropTypes.func,
  renderNodes: PropTypes.func,
  renderComments: PropTypes.func,
  renderLikes: PropTypes.func,
  getCurrentUser: PropTypes.func,
  getChallengeId: PropTypes.func,
  getChallengeTitle: PropTypes.func,
  getToken: PropTypes.func,
  getStatus: PropTypes.func,
  getKnowledgeCategories: PropTypes.func,
  getStatusSolution: PropTypes.func,
  getStatusTaskActive: PropTypes.func,
  getStatusTaskInactive: PropTypes.func,
  graphData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
