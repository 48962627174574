import React from 'react';
import PropTypes from 'prop-types';

const InputTitle = ({formik}) => {
  return (
    <div>
      <label htmlFor={`title`}>Title</label>
      <input
        id={`title`}
        name={`title`}
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.title}
        className = {formik.touched.title && formik.errors.title ? "form-control is-invalid" : "form-control"}
      />
      {formik.touched.title && formik.errors.title ? <div className="alert-form">{formik.errors.title}</div> : null}
    </div>
  );
}

InputTitle.propTypes = {
  formik: PropTypes.object
}

export default InputTitle
