import React, {useRef} from 'react';
import { TrixEditor } from "react-trix";
import PropTypes from 'prop-types';

const InputContent = ({formik}) => {
  const trixRef = useRef(null)

  const handleChange = () => {
    const hiddenInput = document.getElementById(`input-${trixRef.current.id}`)
    formik.values.content = hiddenInput.value
  }

  return (
    <div>
      <label htmlFor={`content`}>Content</label>
      <TrixEditor
        ref={trixRef}
        onChange={handleChange}
        value={formik.values.content}
        fileParamName="blob"
        className = {formik.touched.content && formik.errors.content ? "form-control is-invalid" : "form-control"}
      />
      {formik.touched.content && formik.errors.content ? <div className="alert-form">{formik.errors.content}</div> : null}
    </div>
  );
}

InputContent.propTypes = {
  formik: PropTypes.object
}

export default InputContent
