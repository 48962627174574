import axios from "axios";
import { rootUrl } from "../../../../helpers/utilities";
import {
  FETCH_DATA,
  CLEAR_DATA,
  ADD_LINK,
  DELETE_LINK,
  MOVE_NODE,
  CREATE_IDEA,
  CREATE_NEED,
  CREATE_KNOWLEDGE,
  DELETE_NODE,
  UPDATE_NODE,
  EVOLVE_NODE,
} from "../actions/actionsTypes";

import {
  nodeStatus,
} from "../../../../helpers/utilities";

// Functions fetching the API
export const fetchData = () => {
  // Use Redux Thunk
  return async (dispatch, getState) => {
    const nodesIdeas = await axios({
      url: `${rootUrl}/api/v1/challenges/${getState().challengeId}/ideas`,
      method: "get",
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));
    const nodesNeeds = await axios({
      url: `${rootUrl}/api/v1/challenges/${getState().challengeId}/needs`,
      method: "get",
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));
    const nodesKnowledges = await axios({
      url: `${rootUrl}/api/v1/challenges/${
        getState().challengeId
      }/project_knowledges`,
      method: "get",
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));

    const nodesWithStatus = nodesIdeas.concat(nodesNeeds);

    // Sort the array within their status in order to draw the elements in a specific order inside the canva (that way the smaller elements keep display in front of the larger)
    nodesWithStatus.sort((a, b) => {
      return nodeStatus.indexOf(b.status) - nodeStatus.indexOf(a.status);
    });
    const nodesData = nodesWithStatus.concat(nodesKnowledges);

    // FETCH LINKS
    const links = await axios({
      url: `${rootUrl}/api/v1/challenges/${getState().challengeId}/links`,
      method: "get",
    })
      .then((response) => response.data)
      .catch((error) => console.log(error));

    dispatch({ type: FETCH_DATA, payload: { nodes: nodesData, links: links } });
  };
};

export const clearData = () => {
  return {
    type: CLEAR_DATA,
    payload: { nodes: [], links: [] },
  };
};

// FUNCTIONS FOR THE CHANNELS (ACTION CABLE)

// LINKS
export const renderLinks = (data, graphData) => {
  if (data.link) {
    const newLinks = graphData.links.concat([data.link]);
    const nodes = graphData.nodes;
    // Si le lien est créé on rétabli la couleur originale du noeud et on supprime la propriété du changement de couleur du noeud selectionné
    nodes.forEach((node) => {
      if (node.color_old) {
        node.color = node.color_old;
      }
      delete node.color_selected;
    });
    return {
      type: ADD_LINK,
      payload: { nodes: nodes, links: newLinks },
    };
  } else if (data.links) {
    const nodes = graphData.nodes;
    // Si le lien est supprimé on rétabli la couleur originale du noeud et on supprime la propriété du changement de couleur du noeud selectionné
    nodes.forEach((node) => {
      if (node.color_old) {
        node.color = node.color_old;
      }
      delete node.color_selected;
    });
    return {
      type: DELETE_LINK,
      payload: { nodes: nodes, links: data.links },
    };
  }
};

// NODES
export const renderNodes = (data, graphData, user) => {
  // Si l'action qui arrive est le déplacement d'un noeud
  if (data.nodeMoved) {
    const newNode = data.nodeMoved;
    const oldNodes = graphData.nodes;
    // On remplace les liens du noeud qui a été déplacé par des nouveaux liens
    const links = graphData.links.map(
      (link) => data.links.find((l) => l.id === link.id) || link
    );
    const newNodes = oldNodes.map((oldNode) =>
      oldNode.id === newNode.id ? newNode : oldNode
    );
    return {
      type: MOVE_NODE,
      payload: { nodes: newNodes, links: links },
    };
  }
  // Si l'action qui arrive est un la création d'une nouvelle idée
  else if (data.newIdea) {
    const newNodes =
      user.admin || data.newIdea.user.id === user.id
        ? graphData.nodes.concat([data.newIdea])
        : graphData.nodes;
    const links = graphData.links;
    return {
      type: CREATE_IDEA,
      payload: { nodes: newNodes, links: links },
    };
  }
  // Si l'action qui arrive est un la création d'un noueau besoin (app/channel/node_channel)
  else if (data.newNeed) {
    const newNodes =
      user.admin || data.newNeed.user.id === user.id
        ? graphData.nodes.concat([data.newNeed])
        : graphData.nodes;
    const links = graphData.links;
    return {
      type: CREATE_NEED,
      payload: { nodes: newNodes, links: links },
    };
  }
  // Si l'action qui arrive est un la création d'un noueau knowledge
  else if (data.newKnowledge) {
    const newNodes = graphData.nodes.concat([data.newKnowledge]);
    const links = graphData.links;
    return {
      type: CREATE_KNOWLEDGE,
      payload: { nodes: newNodes, links: links },
    };
  }
  // si l'action qui arrive est la suppression d'un noeud
  else if (data.idDelete) {
    const newNodes = graphData.nodes.filter(
      (node) => node.id !== data.idDelete
    );
    const newLinks = graphData.links.filter((link) => {
      return (
        link.source.id !== data.idDelete && link.target.id !== data.idDelete
      );
    });
    return {
      type: DELETE_NODE,
      payload: { nodes: newNodes, links: newLinks },
    };
  }
  // si l'action qui arrive est l'update d'un noeud
  else if (data.updateNode) {
    const newNodes = graphData.nodes.map((node) =>
      node.id === data.updateNode.id ? data.updateNode : node
    );
    return {
      type: UPDATE_NODE,
      payload: { nodes: newNodes, links: graphData.links },
    };
  }
  // si l'action qui arrive est l'évolution d'un noeud
  else if (data.nodeEvolved) {
    var newNodes = graphData.nodes.map((node) =>
      node.id === data.nodeEvolved.id ? data.nodeEvolved : node
    );
    // On regarde si des noeuds doivent être cachés lors de l'évolution
    if (data.hiddenNodesIds) {
      newNodes = newNodes.filter(
        (node) => !data.hiddenNodesIds.includes(node.id)
      );
    }
    // On remplace les liens du noeuds qui vient d'évoluer par les nouveaux liens (pour que les liens suivent bien lors d'un déplacement après avoir fait évoluer un noeud)
    var links = graphData.links.map(
      (link) => data.links.find((l) => l.id === link.id) || link
    );
    // On regarde si des liens doivent être cachés lors de l'évolution
    if (data.hiddenLinksIds) {
      links = links.filter((link) => !data.hiddenLinksIds.includes(link.id));
    }
    return {
      type: EVOLVE_NODE,
      payload: { nodes: newNodes, links: links },
    };
  }
};
