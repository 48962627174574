// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick/chart.js")
require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "@fortawesome/fontawesome-free/css/all.css"
import "../stylesheets/application.scss"
import "bootstrap";
import 'dropzone/dist/min/dropzone.min.css';
import 'dropzone/dist/min/basic.min.css';

  const images = require.context('../images', true)

// Internal imports :
import { initNotificationCable } from '../channels/notification_channel';
import { carouselChallenges } from '../components/carousel';
// Pour le moment on utilise plus le direct download d'Active Storage, on passe par Dropzone (Stimulus)
// import {directUpload} from '../components/direct-upload';
import renderGraph from '../components/utilities/renderGraph';
import { slickCarousel } from '../components/slick-carousel';

import { initFlatpickr } from "../components/flatpickr";
import { displayAlert } from '../components/sweet-alert';
import { bottomScroller } from "../components/bottom-scroller";
// import { stayDuration } from "../components/stayDuration";
import { initChatroomCable } from "../channels/chatroom_channel";
import { submitCheckboxForm } from  "../components/submitCheckboxForm";
import { turbolinksPersistScroll } from "../components/turbolinksPersistScroll"

// Stimulus
import 'controllers';

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  carouselChallenges();
  slickCarousel();
  initFlatpickr();
  displayAlert();
  bottomScroller();
  renderGraph();
  // stayDuration();
  initChatroomCable();
  submitCheckboxForm();
});

turbolinksPersistScroll()

// Be able to call it in js.erb
window.slickCarousel = slickCarousel;

// We didn't include this line in turbolinks cause we want to subscribe to this channel only one time
initNotificationCable();

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    console.log("Will the service worker register?");
    navigator.serviceWorker.register('/service-worker.js')
      .then(function(reg){
        console.log("Yes, it did.");
    }).catch(function(err) {
        console.log("No it didn't. This happened:", err)
    });
  }
})
