import { ADD_NODE_LINK, CLEAN_NODE_LINK } from "../actions/actionsTypes";

export default function (state = [], action) {
  switch (action.type) {
    case ADD_NODE_LINK:
      return [...state, action.payload];
    case CLEAN_NODE_LINK:
      return action.payload;
    default:
      return state;
  }
}
