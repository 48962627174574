import {limitMindmap, limitBalises, spaceBetweenBalise} from './limits-mindmap';

export const designConstants = (ctx) => {
  // Limit Mindmap
  ctx.setLineDash([])
  ctx.strokeStyle = '#e2e2e2'
  ctx.strokeRect(Math.min(...limitMindmap),Math.min(...limitMindmap),2 * Math.max(...limitMindmap), 2 * Math.max(...limitMindmap));
  
  // Balises
  const coords = []
  let num = 0

  for (let i = Math.min(...limitBalises); i <= Math.max(...limitBalises); i += spaceBetweenBalise) {
    for (let j = Math.min(...limitBalises); j <= Math.max(...limitBalises); j += spaceBetweenBalise) {
      num++
      coords.push({x: i, y: j, num: num})
    }
  }
  
  ctx.fillStyle = "white";
  coords.forEach(({x, y, num}) => {
    ctx.font = '60px serif';
    ctx.fillText(`${num}`,x , y);
  })
}