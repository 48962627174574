import {
  CLOSE_SELECTED_NODE,
  SELECT_NODE,
  DESTROY_THUMBNAIL,
  DESTROY_VIDEO,
  DESTROY_DOCUMENT,
  ADD_USER_TO_TEAM,
  UPDATE_TASK,
  ADD_COMMENT,
  DELETE_COMMENT,
  MODERATE_COMMENT,
  LIKE_SELECTED_NODE,
  UNLIKE_SELECTED_NODE
} from "./actionsTypes";

import axios from "axios";

import {
  rootUrl,
  getUserName,
  camelCaseToSnakeCase,
} from "../../../../helpers/utilities";

export const closeSelectedNode = () => {
  return {
    type: CLOSE_SELECTED_NODE,
    payload: {},
  };
};

export const addComment = (comment) => {
  return {
    type: ADD_COMMENT,
    payload: comment,
  };
};

export const deleteComment = (comment) => {
  return {
    type: DELETE_COMMENT,
    payload: comment,
  };
};

export const moderateComment = (comment) => {
  return {
    type: MODERATE_COMMENT,
    payload: comment
  }
}

export const likeSelectedNode = (like) => {
  return {
    type: LIKE_SELECTED_NODE,
    payload: like,
  };
};

export const unLikeSelectedNode = (like) => {
  return {
    type: UNLIKE_SELECTED_NODE,
    payload: like,
  };
};

export const selectNode = (node, challengeId) => {
  return async (dispatch) => {
    try {
      let selectedNode = null;
      if (node) {
        selectedNode = await axios({
          method: "get",
          url: `${rootUrl}/api/v1/challenges/${challengeId}/${camelCaseToSnakeCase(
            node?.type
          )}s/${node?.id}`,
        });
      }

      dispatch({
        type: SELECT_NODE,
        payload: selectedNode?.data || node,
      });
    } catch (error) {
      console.log("%cerror", "background: purple; color: white", error);
    }
  };
};

export const destroyThumbnail = (id) => {
  // Use Redux Thunk
  return async (dispatch, getState) => {
    axios({
      method: "delete",
      url: `${rootUrl}/destroy_annexe/${id}`,
      headers: {
        "X-CSRF-Token": getState().token,
      },
    })
      .then(() => {
        const node = getState().selectedNode;
        const newNode = { ...node, thumbnail: null };
        dispatch({ type: DESTROY_THUMBNAIL, payload: newNode });
      })
      .catch((error) => console.log(error));
  };
};

export const destroyVideo = (id) => {
  // Use Redux Thunk
  return async (dispatch, getState) => {
    axios({
      method: "delete",
      url: `${rootUrl}/destroy_annexe/${id}`,
      headers: {
        "X-CSRF-Token": getState().token,
      },
    })
      .then(() => {
        const node = getState().selectedNode;
        const newNode = { ...node, video: null };
        dispatch({ type: DESTROY_VIDEO, payload: newNode });
      })
      .catch((error) => console.log(error));
  };
};

export const destroyDocument = (id) => {
  // Use Redux Thunk
  return async (dispatch, getState) => {
    axios({
      method: "delete",
      url: `${rootUrl}/destroy_annexe/${id}`,
      headers: {
        "X-CSRF-Token": getState().token,
      },
    })
      .then(() => {
        const node = getState().selectedNode;
        // Si le noeud a un document (=Knowledge)
        if (node.document) {
          const newNode = { ...node, document: null };
          dispatch({ type: DESTROY_DOCUMENT, payload: newNode });
        }
        // Si le noeud a plusieurs documents (=Need / Idea)
        else if (node.documents) {
          // On enleve le document qui vient d'être supprimé
          const newDocs = node.documents.filter((doc) => doc.id !== id);
          const newNode = { ...node, documents: newDocs };
          dispatch({ type: DESTROY_DOCUMENT, payload: newNode });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const handleAddTeam = ({ role }) => {
  return async (dispatch, getState) => {
    axios({
      method: "post",
      url: `${rootUrl}/team_ideas`,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getState().token,
      },
      data: {
        team_idea: {
          user_id: getState().currentUser.id,
          role: role,
          idea_id: getState().selectedNode.id,
        },
      },
    })
      .then(() => {
        const selectedNode = getState().selectedNode;
        selectedNode.team.push({
          user: {
            name: getUserName(getState().currentUser),
            avatar: getState().currentUser.avatar,
            id: getState().currentUser.id,
          },
          status: "pending",
          role: role,
        });
        dispatch({ type: ADD_USER_TO_TEAM, payload: selectedNode });
      })
      .catch((error) => console.log(error));
  };
};

export const updateTask = (attr, user, status) => {
  return async (dispatch, getState) => {
    const getNextStatus = (status) => {
      const activeTaskStatus = getState().statusTaskActive;
      const index = activeTaskStatus.findIndex((x) => x === status);
      if (
        activeTaskStatus.includes(status) &&
        index !== activeTaskStatus.length - 1
      ) {
        return activeTaskStatus[index + 1];
      } else if (
        activeTaskStatus.includes(status) &&
        index === activeTaskStatus.length - 1
      ) {
        return activeTaskStatus[0];
      } else {
        throw "Status is not in the list!";
      }
    };

    const nextStatus = getNextStatus(status);

    // Si l'utilisaeur qui envoie la requete == utilisateur qui est deja associé à la task || si pas d'utilisateur associé à la task
    if (
      user.id === getState().selectedNode.progress[`${attr}_user`].id ||
      getState().selectedNode.progress[`${attr}_user`].id == undefined
    ) {
      axios({
        method: "patch",
        url: `${rootUrl}/api/v1/challenges/${getState().challengeId}/ideas/${
          getState().selectedNode.id
        }/update_task`,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getState().token,
        },
        data: {
          idea: {
            [`${attr}_user_id`]: nextStatus != "active" ? user.id : null,
            [`${attr}_status`]: nextStatus,
          },
        },
      })
        .then(() => {
          const selectedNode = getState().selectedNode;
          (selectedNode.progress[`${attr}_user`]["name"] =
            nextStatus != "active" ? getUserName(user) : null),
            (selectedNode.progress[`${attr}_status`] = nextStatus);
          dispatch({ type: UPDATE_TASK, payload: selectedNode });
        })
        .catch((error) => {
          window.alert(
            "You probably don't have the rights to do this action, be sure you're on the team for this project"
          );
          console.log(error);
        });
    } else {
      window.alert("You can't update this task, somebody is already doing it.");
    }
  };
};
