import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Task from './Task'

const Prototype = ({
  // Redux
  selectedNode
}) => {


  return (
    <>
      <i className="fas fa-sort-up task-title-arrow"></i>
      <div className="task-title">Prototype</div>
      <div className='container-task'>
        <div className="row d-flex justify-content-center task-row">
          <Task 
            title="Specifications" attr='specifications_written' 
            status={selectedNode.progress.specifications_written_status} 
            user_name={selectedNode.progress.specifications_written_user.name} />
          <Task 
            title="Presentation" attr='updated_presentation' 
            status={selectedNode.progress.updated_presentation_status} 
            user_name={selectedNode.progress.updated_presentation_user.name} />
          <Task 
            title="Prototype" attr='prototype_created' 
            status={selectedNode.progress.prototype_created_status} 
            user_name={selectedNode.progress.prototype_created_user.name} />
          <Task 
            title="New pictures" attr='new_pictures' 
            status={selectedNode.progress.new_pictures_status} 
            user_name={selectedNode.progress.new_pictures_user.name}/>
        </div>
        <div className="row d-flex justify-content-center task-row">
          <Task 
            title="Business Model" attr='video_added' 
            status={selectedNode.progress.video_added_status} 
            user_name={selectedNode.progress.video_added_user.name} />
          <Task 
            title="Alpha testing" attr='alpha_testing_report' 
            status={selectedNode.progress.alpha_testing_report_status} 
            user_name={selectedNode.progress.alpha_testing_report_user.name} />
          <Task 
            title="Go from authority" attr='go_from_authority_prototype' 
            status={selectedNode.progress.go_from_authority_prototype_status} 
            user_name={selectedNode.progress.go_from_authority_prototype_user.name} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode
  }
} 

Prototype.propTypes = {
  selectedNode: PropTypes.object,
}

export default connect(mapStateToProps, null)(Prototype)