import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "knowledges", "ideas", "needs", "users", "knowledgesTab", "ideasTab", "needsTab", "usersTab" ]

  connect() {
    const numChildrenKnowledge = this.knowledgesTarget.querySelectorAll('.result').length;
    const numChildrenIdeas = this.ideasTarget.querySelectorAll('.result').length;
    const numChildrenNeeds = this.needsTarget.querySelectorAll('.result').length;
    const numChildrenUsers = this.usersTarget.querySelectorAll('.result').length;
    const maxChildren = Math.max(numChildrenKnowledge, numChildrenIdeas, numChildrenNeeds, numChildrenUsers);
    switch(maxChildren) {
      case numChildrenIdeas:
        this.ideasTarget.classList.remove('d-none');
        this.ideasTabTarget.classList.add('active');
        break;
      case numChildrenNeeds:
        this.needsTarget.classList.remove('d-none');
        this.needsTabTarget.classList.add('active');
        break;
      case numChildrenKnowledge:
        this.knowledgesTarget.classList.remove('d-none');
        this.knowledgesTabTarget.classList.add('active');
        break;
      case numChildrenUsers:
        this.usersTarget.classList.remove('d-none');
        this.usersTabTarget.classList.add('active');
        break;
      default:
        return
    }
    
  }

  select(e) {
    document.querySelectorAll('.tab-content').forEach(tab => {
      tab.classList.add('d-none');
    })
    document.querySelectorAll('.search-tab').forEach(tab => {
      tab.classList.remove('active');
    })
    const clickedTab = e.currentTarget.id
    this[`${clickedTab}Target`].classList.remove('d-none');
    this[`${clickedTab}TabTarget`].classList.add('active');
  }
}