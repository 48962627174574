import {GET_LINKED_NODES, CLEAN_LINKED_NODES} from '../actions/actionsTypes';

export default function(state = [], action) {
  switch(action.type) {
    case GET_LINKED_NODES:
      return [...action.payload]
    case CLEAN_LINKED_NODES:
      return [...action.payload]
    default: return state
  }
}