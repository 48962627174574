import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Comment from '../Comment';
import InputComment from '../InputComment';

const TabComments = ({
  // Own Props
  activeTab,
  node,
  linked,
  // From redux state
  currentUser
}) => {
  // Render comments
  const listComments = node.comments.map(comment => {
    return (
      <Comment
        key={comment.id}
        content={comment.content}
        author={comment.user.full_name_short}
        authorId={comment.user.id}
        date={comment.created_at}
        currentUser={currentUser}
        id={comment.id}
        avatar={comment.user.avatar}
        moderated={comment.moderated}
      />
    )
  });

  const renderContent = () => {
    if(activeTab) {
      return (
        <div className="container-card-comment">
          <div className="comment-container">
            {node.comments.length > 0 ? listComments : <div className="comment-empty">No comments yet</div>}
          </div>
          {linked ? null : <InputComment/>}
        </div>
      )
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    token: state.token
  }
}

TabComments.propTypes = {
  // Own Props
  activeTab: PropTypes.bool,
  node: PropTypes.object,
  linked: PropTypes.bool,

  // From redux
  currentUser: PropTypes.object,
}


export default connect(mapStateToProps, null)(TabComments)
