import React from "react";
import ShowCardElement from "./ShowCardElement";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowElement = ({
  // From redux State
  selectedNode,
}) => {
  if (selectedNode && Object.keys(selectedNode).length !== 0) {
    return <ShowCardElement />;
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode,
  };
};

ShowElement.propTypes = {
  // From redux
  selectedNode: PropTypes.object,
};

export default connect(mapStateToProps, null)(ShowElement);
