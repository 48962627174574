const submitCheckboxForm = () => {
  const form = document.querySelector(".simple_form.edit_counter_point")
  if (form) {
    const checkboxes = document.querySelectorAll('label')
    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('click', (e) => {
        e.currentTarget.previousElementSibling.checked = !e.currentTarget.previousElementSibling.checked
        e.currentTarget.nextElementSibling.click()
      })
    })
  }
}

export { submitCheckboxForm }