import {
  GET_CHALLENGE_ID
} from './actionsTypes';


export const getChallengeId = (challengeId) => {
  return {
    type: GET_CHALLENGE_ID,
    payload: challengeId
  }
}