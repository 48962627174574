// Nodes linked to solution, show only if the selectedNode is an Idea with status > 'Potential'

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchNodesLinked, cleanLinkedNodes } from '../../redux/actions/linkedNodesActions';
import {cleanLinkedNodeActive} from '../../redux/actions/linkedNodeActiveActions';
import { setLinkedNodeActive } from '../../redux/actions/linkedNodeActiveActions';

import {rootUrl} from '../../../../helpers/utilities';

import LinkedNode from '../LinkedNodes/LinkedNode';

const TabNodesLinked = ({
  // Own props
  activeTab,

  // Redux
  selectedNode,
  linkedNodeActive,
  fetchNodesLinked,
  cleanLinkedNodes,
  cleanLinkedNodeActive,
  linkedNodes,
  setLinkedNodeActive
}) => {
  const [arrayIdeas, setArrayIdeas] = useState([])
  const [arrayNeeds, setArrayNeeds] = useState([])
  const [arrayKnowledges, setArrayKnowledges] = useState([])

  useEffect(() => {

    // si les linked Nodes n'ont pas encore été fetch on les fetch quand on entre sur la tab
    fetchNodesLinked()
    return () => {
      cleanLinkedNodes()
      cleanLinkedNodeActive()
      setArrayIdeas([])
      setArrayNeeds([])
      setArrayKnowledges([])
    }
  }, [selectedNode])

  useEffect(() => {
    linkedNodes.forEach(node => {
      switch(node.type) {
        case "Idea":
          return setArrayIdeas(arrayIdeas => [...arrayIdeas, node])
        case "Need":
          return setArrayNeeds(arrayNeeds => [...arrayNeeds, node])
        case "ProjectKnowledge":
          return setArrayKnowledges(arrayKnowledges => [...arrayKnowledges, node])
      }
    })
  }, [linkedNodes])

  const renderAvatar = (node) => {
    if(node.user.avatar) {
      return (
        <div className="very-small-avatar">
          <a href={`${rootUrl}/profiles/${node.user.id}`}>
            <img src={`${node.user.avatar.url}`}/>
          </a>
        </div>
      )
    } else {
      return (
        <a href={`${rootUrl}/profiles/${node.user.id}`}>
          <div className="very-small-avatar-default">
          </div>
        </a>
      )
    }
  }

  const renderNodes = (array) => {
    return array.map(node => {
      return(
        <div key={node.id} onClick={() => setLinkedNodeActive(node)} className='card-linked-node very-small-text section-text-white align-text-center' >
          <div className="d-flex flex-column card-linked-node-text-details">
            {node.name}
          </div>
          <div className="d-flex align-items-center">
            <div className="pr-1">
              {renderAvatar(node)}
            </div>
            <div className="card-linked-node-text-details">
              {node.user.full_name_short}
            </div>
          </div>
        </div>
      )
    })
  };

  const renderContent = () => {
    if(activeTab) {
      // Si un noeuds lié a été selectionné
      if(linkedNodeActive && Object.keys(linkedNodeActive).length > 0) {
        return(
          <LinkedNode node={linkedNodeActive}/>
        )
      }
      else if(linkedNodes.length > 0) {
          return(
            <>
              <div className="title-14 section-text-white mindmapmodal-container-title d-flex align-items-center">
                <div className="mindmapmodal-title-icon mr-2 mb-1" id="linked-node-need-icon"></div>
                Needs
              </div>
              <div className="linked-node-container section-text-white" id="linked-needs-container">
                {renderNodes(arrayNeeds)}
              </div>
              <div className="title-14 section-text-white mindmapmodal-container-title my-2 d-flex align-items-center">
                <div className="mindmapmodal-title-icon mr-2 mb-1" id="linked-node-idea-icon"></div>
                Ideas
              </div>
              <div className="linked-node-container section-text-white" id="linked-ideas-container">
                {renderNodes(arrayIdeas)}
              </div>
              <div className="title-14 section-text-white mindmapmodal-container-title my-2 d-flex align-items-center">
                <div className="mindmapmodal-title-icon mr-2 mb-1" id="linked-node-knowledge-icon"></div>
                Knowledges
              </div>
              <div className="linked-node-container section-text-white" id="linked-knowledges-container">
                {renderNodes(arrayKnowledges)}
              </div>
            </>
          )
      } else {
        return(
          <div className="section-text-white middle-text m-5">
            No nodes linked
          </div>
        )
      }
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    linkedNodes: state.linkedNodes,
    linkedNodeActive: state.linkedNodeActive,
    selectedNode: state.selectedNode
  }
}

const mapDispatchToProps = dispatch => {
  return{
    fetchNodesLinked: () => dispatch(fetchNodesLinked()),
    cleanLinkedNodes: () => dispatch(cleanLinkedNodes()),
    cleanLinkedNodeActive: () => dispatch(cleanLinkedNodeActive()),
    setLinkedNodeActive: (node) => dispatch(setLinkedNodeActive(node))
  }
}

TabNodesLinked.propTypes = {
  activeTab: PropTypes.bool,

  // Redux
  selectedNode: PropTypes.object,
  linkedNodes: PropTypes.array,
  fetchNodesLinked: PropTypes.func,
  cleanLinkedNodes: PropTypes.func,
  cleanLinkedNodeActive: PropTypes.func,
  linkedNodeActive: PropTypes.object,
  setLinkedNodeActive: PropTypes.func
}


export default connect(mapStateToProps, mapDispatchToProps)(TabNodesLinked)
