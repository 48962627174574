import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

import MindMapCarousel from './MindMapCarousel';

const CarouselUniverse = ({universes}) => {
  const renderMindMaps = universes.map(universe => {
    return(
      <MindMapCarousel universeId={universe.id} universeTitle={universe.title} key={universe.id}/>
    )
  })
  return (
    <div>
       <Carousel className='opacity-0' showThumbs={false}>
         {renderMindMaps}
      </Carousel>
    </div>
  )
}

CarouselUniverse.propTypes = {
  universes: PropTypes.array,
};

export default CarouselUniverse
