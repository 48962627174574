import { GET_LINKED_NODES, CLEAN_LINKED_NODES } from "./actionsTypes";

import axios from 'axios';

import {rootUrl} from '../../../../helpers/utilities'

export const fetchNodesLinked = () => {
  // Use Redux Thunk
  return async(dispatch, getState) => {
    if(getState().selectedNode.type == 'Idea' && getState().statusSolution.includes(getState().selectedNode.status)) {
      const response = await axios({
        url: `${rootUrl}/api/v1/challenges/${getState().challengeId}/ideas/${getState().selectedNode.id}/linked_solution`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getState().token
        },
        method: 'get'
      })
        .then(response => response.data)
        .catch(error => console.log(error))

        dispatch({ type: GET_LINKED_NODES, payload: response})
    }
  }
}

export const cleanLinkedNodes = () => {
  return {
    type: CLEAN_LINKED_NODES,
    payload: []
  }
}