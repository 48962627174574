import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {rootUrl} from '../../../helpers/utilities';

import {destroyThumbnail} from '../redux/actions/selectedNodeActions';


const InputThumbnail = ({selectedNode, destroyThumbnail}) => {

  const [thumbnail, setThumbnail] = useState(selectedNode.thumbnail)

  useEffect(() => {
    setThumbnail(selectedNode.thumbnail)
  }, [selectedNode])
  
  const handleDeleteThumbnail = (id) => {
    destroyThumbnail(id);
  }
  
  const renderThumbnail = () => {
    // Return the Thumnail + option to destroy || Return the Input to add a thumbnail
    if(thumbnail) {
      return(
        <div className="card-element-thumbnail">
          <img src={`${thumbnail.url}`} alt={`${thumbnail.name}`}/>
          <div className="link" onClick={() => handleDeleteThumbnail(thumbnail.id)}>Destroy</div>
          <input type="hidden" id={`thumbnail`} defaultValue={thumbnail.key}/>
        </div>
      )
    } else {
      return(
        <div className="dropzone dropzone-default dz-clickable" 
        data-controller="dropzone" 
        data-max-file-size="2" 
        data-dropzone-max-files="1"
        data-dropzone-max-file-size="1"
        data-dropzone-accepted-files="image/*"
        >
          <div className={`form-group file optional thumbnail`}>
            <label className="file optional" htmlFor={`thumbnail`}>thumbnail</label>
            <input 
              data-direct-upload-url={`${rootUrl}/rails/active_storage/direct_uploads`}
              className="form-control-file file optional" 
              type="file" 
              name={`thumbnail`} 
              id={`thumbnail`} 
              data-dropzone-target="input" 
              disabled="" 
              style={{display: 'none'}} />
          </div>
          <div className="dz-default dz-message">
            <button className="dz-button" type="button">Drop files here to upload or click to browse</button>
          </div>
          <span className="dropzone-msg-desc text-sm">
            <p>Max size : 1 MB</p>
            <p>Must be an image format</p>
          </span>
        </div>
      )
    }
  }

  return (
    renderThumbnail()
  )
}

const mapStateToProps = state => {
  return {
    selectedNode: state.selectedNode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyThumbnail: (id) => dispatch(destroyThumbnail(id)),
  }
}

InputThumbnail.propTypes = {
  // From Redux
  destroyThumbnail: PropTypes.func,
  selectedNode: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputThumbnail);
