import React from 'react';
import PropTypes from 'prop-types';
import {useFormik, Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { connect } from 'react-redux'

import {rootUrl} from '../../../helpers/utilities';

import InputTitle from '../forms-input/InputTitle';
import InputContent from '../forms-input/InputContent';
import InputThumbnail from '../forms-input/InputThumbnail';
import InputVideo from '../forms-input/InputVideo';
import InputDocuments from '../forms-input/InputDocuments';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'



const FormAddNode = ({node, challengeId, token, setIsOpen}) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(80, 'Must be 80 characters or less')
        .required('Required'),
      content: Yup.string()
        .max(10000, 'Must be 10 000 characters or less')
        .required('Required'),
    }),
    onSubmit: values => {

      // For the files we won't use the Formik logic but we retrieve them through formDatas and then merge them to the values
      const formData = {}
      formData['thumbnail'] = document.getElementById('thumbnail') && document.getElementById('thumbnail').value ? document.getElementById('thumbnail').value : null
      formData['video'] = document.getElementById('video') && document.getElementById('video').value ? document.getElementById('video').value : null

      if(document.querySelectorAll('#documents')) {
        document.querySelectorAll('#documents').forEach(doc => {
          if(doc.value ) {
            formData['documents'] ? formData['documents'].push(doc.value) : formData['documents'] = [doc.value]
          }
        })
      }

      // Merging the data from Formik (values) and for the "classic way" (formData)
      const nodeValues = {...values, ...formData}

      const data = {
        [node]: nodeValues
      }

      axios({
        url: `${rootUrl}/api/v1/challenges/${challengeId}/${node}s`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        method: 'post',
        data: data
      })
      .then(() => {
        // Close and reset the modal
        setIsOpen(false);
        Swal.fire({
          title: 'Be Careful!',
          text: `You added a new ${node} but it's only a draft version!`,
          icon: 'warning',
          confirmButtonText: 'Draft is Ok for me'
        })
      })
      .catch(error => {
        // Don't disable the btn
        const btnSubmit = document.getElementById(`submit-${node}`);
        btnSubmit.disabled = false;
        // Render errors (see controller/api/v1/needs_controller)
        // We use this technique in order not to have the comma in the list
        let errorsMessage = ''
        error.response.data.errors.forEach(error => {
          errorsMessage += `<li>${error}</li>`
        })
        document.querySelector('body').insertAdjacentHTML('beforeend',
          `<div class="alert alert-warning alert-dismissible fade show m-1" role="alert">
            ${errorsMessage}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`
        )
      })
    },
  });

  return (
    <Formik
      initialValues={formik.initialValues}
      validate={formik.validationSchema}
      onSubmit={formik.onSubmit}
    >
      {({isValid, values}) => (
        <form onSubmit={formik.handleSubmit}>
          <InputTitle formik={formik} />
          <InputContent formik={formik} />
          <InputThumbnail />
          <InputVideo />
          <InputDocuments  numFiles={3}/>

          <button type="submit" id={`submit-${node}`}>Submit</button>
        </form>
      )}

    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    challengeId: state.challengeId,
    token: state.token,
  }
}

FormAddNode.propTypes = {
  token: PropTypes.string,
  challengeId: PropTypes.string,
};

export default connect(mapStateToProps, null)(FormAddNode)
