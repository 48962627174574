import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ahoy from "ahoy.js";
import { connect } from 'react-redux';

// REACT
import TabInfos from './Tabs/TabInfos';
import TabComments from './Tabs/TabComments';
import TabNodesLinked from './Tabs/TabNodesLinked';
import TabTeam from './Tabs/TabTeam';
import TabProgress from './Tabs/TabProgress';

import ModalEditNode from '../forms-edit/ModalEditNode';
import ModalEditKnowledge from '../forms-edit/ModalEditKnowledge';

// REDUX ACTIONS
import { closeSelectedNode, likeSelectedNode, unLikeSelectedNode } from '../redux/actions/selectedNodeActions';
import { updateNotificable } from '../redux/actions/notificationActions';

import {rootUrl} from '../../../helpers/utilities';
// import { durationOnPage } from '../../stayDuration'


const ShowCardElement = ({

  // From redux state
  selectedNode,
  currentUser,
  challengeId,
  token,
  status,
  statusSolution,

  // From redux actions
  closeSelectedNode,
  likeSelectedNode,
  unLikeSelectedNode
}) => {

  const [activeTab, setActiveTab] = useState('Project')

  const clickTab = (event) => {
    setActiveTab(event.currentTarget.innerText)
  }

  useEffect(() => {
    setActiveTab('Project')
  }, [selectedNode.id])

  useEffect(() => {
    // We update each time there is a modification on the node
    updateNotificable(selectedNode, token, currentUser)
  });

  // WE DONT USE THE DURATION CALCULATION FOR THE MOMENT
  useEffect(() => {
  //   // We track at the opening and untrack at the closing
    if (selectedNode.type == "ProjectKnowledge") {
      const tracker = {}
      tracker[`knowledge_id`] = selectedNode.knowledge_id
      tracker["corporate"] = selectedNode.corporate
      // Create a tracker on Component Mount
      ahoy.track(selectedNode.name, tracker);
  //   // We use a set Timeout to compense the SetTimeout in ahoy.js
  //   setTimeout(() => {
  //     // Use the API to get the id
  //     axios({ url: `${rootUrl}/api/v1/ahoy_events`, method: 'get' })
  //     .then(res => res.data)
  //     .then(data => {
  //       const time_to_check = 5000;
  //       // Lauch time counter with the id from the API
  //       const idInterval = setInterval(() => {
  //         durationOnPage(data.id, token)
  //       }, time_to_check);
  //       window.localStorage.setItem(`idInterval`, idInterval);
  //     })
  //   }, 1500);

  //   // Delete time counter on Component Will Unmount
  //   return () => {
  //     setTimeout(() => {
  //       const idInterval = window.localStorage.getItem('idInterval')
  //       if (idInterval) {
  //         clearInterval(idInterval)
  //       }
  //     }, 1500);
  //   }
    }
  }, []);

  // render Delete btn
  const renderDelete = (currentUser) => {
    if(currentUser.admin || currentUser.id === selectedNode.user.id) {
      return(
        <i className="fas fa-trash-alt icon" onClick={handleDeleteNode}></i>
      )
    } else {
      return null
    }
  }

  // render Edit btn
  const renderEdit = (currentUser) => {
    if(currentUser.id === selectedNode.user.id) {
      return(
        <i className="fas fa-edit icon" onClick={showModal}></i>
      )
    } else {
      return null
    }
  }

  const renderModalEdit = () => {
    switch(selectedNode.type) {
      case 'Need':
      case 'Idea':
        return (<ModalEditNode
          node={selectedNode.type.toLowerCase()}
          hideModal={hideModal}
          isOpen={isOpen}
        />)
      case 'ProjectKnowledge':
        return (<ModalEditKnowledge
          node={selectedNode.type.toLowerCase()}
          hideModal={hideModal}
          isOpen={isOpen}
        />)
    }
  }

  // render Avatar

  const renderAvatar = () => {
    if(selectedNode.user.avatar) {
      return (
        <div className="small-avatar">
          <a href={`${rootUrl}/profiles/${selectedNode.user.id}`}>
            <img src={`${selectedNode.user.avatar.url}`}/>
          </a>
        </div>
      )
    } else {
      return (
        <a href={`${rootUrl}/profiles/${selectedNode.user.id}`}>
          <div className="small-avatar-default">
          </div>
        </a>
      )
    }
  }

  // render likes

  const userAutoLike = () => {
    return selectedNode.likes.map(like => like.user_id).includes(currentUser.id)
  }


  const renderLikes = () => {
    if(userAutoLike()) {
      return(
        <>
          <i className="fas fa-heart mx-1"></i>
          {selectedNode.likes.length}
        </>
      )
    } else {
      return(
        <>
          <i className="far fa-heart mx-1"></i>
          {selectedNode.likes.length}
        </>
      )
    }
  }


    // LIKE
    const handleClickLike = (selectedNode, currentUser, e) => {
      // Si le like existe déjà on le supprime, sinon on l'ajoute
      if(selectedNode.likes.map(like => like.user_id).includes(currentUser.id)) {
        unlike(e)
      } else {
        like(e)
      }
    }

    const unlike = (e) => {
      e.preventDefault();
      axios({
        method: 'delete',
        url: `${rootUrl}/unlike`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        data: {
          like: {
            likable_id: selectedNode.type === "ProjectKnowledge" ? selectedNode.knowledge_id : selectedNode.id,
            likable_type: selectedNode.type === "ProjectKnowledge" ? "Knowledge" : selectedNode.type,
            user_id: currentUser.id
          },
          challengeId: challengeId
        }
      })
      .then((like) => unLikeSelectedNode(like.data))
      .catch(error => {
        console.log({ error })
      })
    }

    const like = (e) => {
      e.preventDefault();
      const likable_type = selectedNode.type === "ProjectKnowledge" ? "Knowledge" : selectedNode.type
      const likable_id = selectedNode.type === "ProjectKnowledge" ? selectedNode.knowledge_id : selectedNode.id
      const likable_user_id = selectedNode.user.id
      const seen = currentUser.id === likable_user_id
      // Send the data using action cable
      axios({
        url: `${rootUrl}/likes`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        method: 'post',
        data: {
          like: {
            user_id: currentUser.id,
            likable_id: likable_id,
            likable_type: likable_type,
            likable_user_id: likable_user_id,
            seen: seen
          }
        }
      })
      .then((like) => likeSelectedNode(like.data))
      .catch(error => {
        console.log({ error })
      })
    }

  // render comments lenght //

  const renderCommentsCount = () => {
    return(
      <>
        <i className="fas fa-comments mx-1"></i>
        {selectedNode.comments.length}
      </>
    )
  }

  /// render Node Type Image //
  const renderNodeImage = (nodeType) => {
    if(nodeType == 'Idea') {
      return (
        <div className="modal-type-Idea modal-type-image"></div>
      )
    }
    if(nodeType == 'Need') {
      return (
        <div className="modal-type-Need modal-type-image"></div>
      )
    } else {
      return (
        <div className="modal-type-knowledge modal-type-image"></div>
      )
    }
  }

  // MODAL
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  }

  const hideModal = () => {
    setIsOpen(false);
  };

  // DELETE NODE
  const handleDeleteNode = () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const url_request = selectedNode.type != 'ProjectKnowledge' ?
        `${rootUrl}/api/v1/challenges/${challengeId}/${selectedNode.type.toLowerCase()}s/${selectedNode.id}`
        : `${rootUrl}/api/v1/challenges/${challengeId}/project_knowledges/${selectedNode.knowledge_id}`
      axios({
        headers: {
          'Content-Type': 'application/js',
          'X-CSRF-Token': token
        },
        method: 'delete',
        url: url_request,
      })
      .then(() => {
        closeSelectedNode()
      })
      .catch(error => console.log(error))
    }
  }

  const handleEvolve = (newStatus) => {
    axios({
      url: `${rootUrl}/api/v1/${selectedNode.type.toLowerCase()}s/evolve`,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      data: {
        challenge_id: challengeId,
        id: selectedNode.id,
        [selectedNode.type.toLowerCase()]: {
          status: newStatus,
        }
      },
      method: 'patch',
    })
    .then()
    .catch(error => console.log(error))
  }

  // render Evolve btn
  const renderButtonEvolve = (nodeType) => {
    // On verifie
    // 1- que le node ne soit pas un knowledge
    // ET
    // 2- si l'user est un admin, ou si l'elevation de status ne nécessite pas de rang admin et que le node appartient à l'user
    // ET
    // 3- que le status n'est pas le dernier status
    if (nodeType !== 'ProjectKnowledge') {
      const last_index = status[nodeType].length - 1
      if (
          selectedNode.status !== status[nodeType][last_index].name &&
          (
            currentUser.admin ||
            currentUser.id === selectedNode.user.id && !status[nodeType][status[nodeType].map((obj) => obj.name).indexOf(selectedNode.status)].admin
          )
        ) {
          const new_status_index = status[nodeType].map((obj) => obj.name).indexOf(selectedNode.status) + 1
          const newStatus = status[nodeType][new_status_index].name
          return (
            <button onClick={() => handleEvolve(newStatus)} className='btn-neutral'>
              <div className="validation-icon">
              </div>
            </button>
          )
      } else {
        return null
      }
    }
  }

  // RETURN
  return (
    <div className="card-show-element" data-user-id={selectedNode.user.id} data-id={selectedNode.id} data-type={selectedNode.type}>
      <div className="">
        <div className="close" onClick={closeSelectedNode}>&times;</div>
        {renderNodeImage(selectedNode.type)}
        <div className="d-flex align-items-center justify-content-end general-information-modal">
          <div>
            <div className='d-flex text-title-font middle-text justify-content-end mx-2'>
              <strong>{selectedNode.name}</strong>
            </div>
            <div className='d-flex section-text-white very-small-title' id='subtitle-tab-gnl'>
              <div className="card-like mx-2" onClick={(e) => handleClickLike(selectedNode, currentUser, e)}>
                {renderLikes()}
              </div>
              <div className="mx-2"> {renderCommentsCount()}</div>
              <div className="mx-2 subtitle-tab-gnl-user-name"> by {selectedNode.user.full_name_short}</div>
            </div>
          </div>
          <div>
            {renderAvatar()}
          </div>
          <div className='card-element-details d-flex align-items-end'>
            {renderEdit(currentUser)}
            {renderDelete(currentUser)}
            {renderButtonEvolve(selectedNode.type)}
            {renderModalEdit()}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-column container-tab-content-modal" style={{height: '87%'}}>
        <div style={{height: '100%'}}>
          <TabInfos activeTab={activeTab === 'Project'} node={selectedNode}/>
          <TabComments activeTab={activeTab === 'Comments'} node={selectedNode}/>
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <TabNodesLinked activeTab={activeTab === 'Linked'} /> : null}
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <TabTeam activeTab={activeTab === 'Team'} /> : null}
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <TabProgress activeTab={activeTab === 'Progress'} /> : null}
        </div>

        <div className="d-flex">
          <div className={activeTab === 'Project' ? 'card-tab active' : 'card-tab'} onClick={clickTab} id='tab-1'>Project</div>
          <div className={activeTab === 'Comments' ? 'card-tab active' : 'card-tab'} onClick={clickTab} id='tab-2'>Comments</div>
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <div className={activeTab === 'Linked' ? 'card-tab active' : 'card-tab'} onClick={clickTab} id='tab-3'>Linked</div> : null}
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <div className={activeTab === 'Team' ? 'card-tab active' : 'card-tab'} onClick={clickTab} id='tab-4'>Team</div> : null}
          {selectedNode.type == 'Idea' && statusSolution.includes(selectedNode.status) ? <div className={activeTab === 'Progress' ? 'card-tab active' : 'card-tab'} onClick={clickTab} id='tab-5'>Progress</div> : null}
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    selectedNode: state.selectedNode,
    currentUser: state.currentUser,
    challengeId: state.challengeId,
    token: state.token,
    status: state.status,
    statusSolution: state.statusSolution
  }
}

const mapDispatchToProps = dispatch => {
  return{
    closeSelectedNode: () => dispatch(closeSelectedNode()),
    likeSelectedNode: (like) => dispatch(likeSelectedNode(like)),
    unLikeSelectedNode: (like) => dispatch(unLikeSelectedNode(like))
  }
}

ShowCardElement.propTypes = {
  handleClickLike: PropTypes.func,
  handleSubmitComment: PropTypes.func,
  handleDeleteNode: PropTypes.func,
  updateNotificable: PropTypes.func,

  // From redux
  selectedNode: PropTypes.object,
  currentUser: PropTypes.object,
  challengeId: PropTypes.string,
  token: PropTypes.string,
  status: PropTypes.object,
  closeSelectedNode: PropTypes.func,
  likeSelectedNode: PropTypes.func,
  unLikeSelectedNode: PropTypes.func,
  statusSolution: PropTypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowCardElement);



