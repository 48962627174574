// GRAPH DATA
export const FETCH_DATA = 'FETCH_DATA'
export const CLEAR_DATA = 'CLEAR_DATA'
export const DELETE_LINK = 'DELETE_LINK'
export const ADD_LINK = 'ADD_LINK'
export const MOVE_NODE = 'MOVE_NODE'
export const CREATE_IDEA = 'CREATE_IDEA'
export const CREATE_NEED = 'CREATE_NEED'
export const CREATE_KNOWLEDGE = 'CREATE_KNOWLEDGE'
export const DELETE_NODE = 'DELETE_NODE'
export const UPDATE_NODE = 'UPDATE_NODE'
export const EVOLVE_NODE = 'EVOLVE_NODE'

// NODESFORLINK
export const ADD_NODE_LINK = 'ADD_NODE_LINK'
export const CLEAN_NODE_LINK = 'CLEAN_NODE_LINK'

// SELECTED NODE
export const CLOSE_SELECTED_NODE = 'CLOSE_SELECTED_NODE'
export const SELECT_NODE = 'SELECT_NODE'
export const DESTROY_THUMBNAIL = 'DESTROY_THUMBNAIL'
export const DESTROY_VIDEO = 'DESTROY_VIDEO'
export const DESTROY_DOCUMENT = 'DESTROY_DOCUMENT'
export const ADD_USER_TO_TEAM = 'ADD_USER_TO_TEAM'
export const UPDATE_TASK = 'UPDATE_TASK'
export const ADD_COMMENT = 'ADD_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const MODERATE_COMMENT = 'MODERATE_COMMENT'
export const LIKE_SELECTED_NODE = 'LIKE_SELECTED_NODE'
export const UNLIKE_SELECTED_NODE = 'UNLIKE_SELECTED_NODE'

// CURRENT USER
export const GET_CURRENT_USER = 'GET_CURRENT_USER'

// CHALLENGE ID
export const GET_CHALLENGE_ID = 'GET_CHALLENGE_ID'


// CHALLENGE ID
export const GET_CHALLENGE_TITLE = 'GET_CHALLENGE_TITLE'

// TOKEN
export const GET_TOKEN = 'GET_TOKEN'

// STATUS NODE
export const GET_STATUS = 'GET_STATUS'
export const GET_STATUS_SOLUTION = 'GET_STATUS_SOLUTION'

// TASK STATUS (WHEN IDEA > Potential)
export const GET_STATUS_TASK_INACTIVE = 'GET_STATUS_TASK_INACTIVE'
export const GET_STATUS_TASK_ACTIVE = 'GET_STATUS_TASK_ACTIVE'

// KNOWLEDGE_CATEGORIES
export const GET_KNOWLEDGE_CATEGORIES = 'GET_KNOWLEDGE_CATEGORIES'

// LINKED_NODES
export const GET_LINKED_NODES = 'GET_LINKED_NODES'
export const CLEAN_LINKED_NODES = 'CLEAN_LINKED_NODES'

// LINKED_NODE_ACTIVE
export const CLEAN_LINKED_NODE_ACTIVE = 'CLEAN_LINKED_NODE_ACTIVE'
export const SET_LINKED_NODE_ACTIVE = 'SET_LINKED_NODE_ACTIVE'
