import {
  FETCH_DATA, CLEAR_DATA,
  ADD_LINK, DELETE_LINK, 
  MOVE_NODE, 
  CREATE_IDEA, CREATE_NEED, CREATE_KNOWLEDGE,
  DELETE_NODE,
  UPDATE_NODE,
  EVOLVE_NODE,
} from '../actions/actionsTypes'

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_DATA:
      return action.payload
    case CLEAR_DATA:
      return action.payload
    // CHANNEL ACTIONS (ACTION CABLE)
    case ADD_LINK:
      return action.payload
    case DELETE_LINK:
      return action.payload
    case MOVE_NODE:
      return action.payload
    case CREATE_IDEA:
      return action.payload
    case CREATE_NEED:
      return action.payload
    case CREATE_KNOWLEDGE:
      return action.payload
    case DELETE_NODE:
      return action.payload
    case UPDATE_NODE:
      return action.payload
    case EVOLVE_NODE:
      return action.payload
    default: return state
  }
}