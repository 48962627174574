import {
  GET_CHALLENGE_TITLE
} from './actionsTypes';


export const getChallengeTitle = (challengeTitle) => {
  return {
    type: GET_CHALLENGE_TITLE,
    payload: challengeTitle
  }
}
