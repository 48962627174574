import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import { addComment } from '../redux/actions/selectedNodeActions';

import {rootUrl} from '../../../helpers/utilities';

const InputComment = ({
    // From redux state
    selectedNode,
    token,
    currentUser,
    addComment,
}) => {

  // COMMENT
  const handleSubmitComment = (e) => {
    e.preventDefault();
    const commentable_type = selectedNode.type === "ProjectKnowledge" ? "Knowledge" : selectedNode.type
    const commentable_id = selectedNode.type === "ProjectKnowledge" ? selectedNode.knowledge_id : selectedNode.id
    const commentable_user_id = selectedNode.user.id
    const content = document.getElementById('input_comment_content').value
    const seen = currentUser.id === commentable_user_id
    // Send the data using action cable
    axios({
      url: `${rootUrl}/comments`,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      method: 'post',
      data: {
        comment: {
          content: content,
          commentable_id: commentable_id,
          commentable_type: commentable_type,
          commentable_user_id: commentable_user_id,
          seen: seen
        }
      }
    })
    .then((comment) => {
      addComment(comment.data)
      e.target.reset()
    })
    .catch(error => console.log(error))
  }

  return (
    <form onSubmit={handleSubmitComment} className='input_comment'>
      <input type='text' placeholder="write a comment..." id='input_comment_content'/>
      <button type="submit" id='btn-send-comment' className="button-no-color">Send</button>
    </form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addComment: (comment) => dispatch(addComment(comment))
  }
}

const mapStateToProps = (state) => {
  return {
    selectedNode: state.selectedNode,
    currentUser: state.currentUser,
    token: state.token
  }
}

InputComment.propTypes = {
  token: PropTypes.string,
  currentUser: PropTypes.object,

  // From redux state
  selectedNode: PropTypes.object,
  addComment: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(InputComment)
