import {combineReducers} from 'redux';
import graphDataReducer from './reducers/graphDataReducer';
import selectedNodeReducer from './reducers/selectedNodeReducer';
import nodesForLinkReducer from './reducers/nodesForLinkReducer';
import currentUserReducer from './reducers/currentUserReducer';
import challengeIdReducer from './reducers/challengeIdReducer';
import challengeTitleReducer from './reducers/challengeTitleReducer';
import tokenReducer from './reducers/tokenReducer';
import statusReducer from './reducers/statusReducer';
import statusSolutionReducer from './reducers/statusSolutionReducer';
import knowledgeCategoriesReducer from './reducers/knowledgeCategoriesReducer';
import linkedNodesReducer from './reducers/linkedNodesReducer'
import linkedNodeActiveReducer from './reducers/linkedNodeActiveReducer';
import statusTaskInactiveReducer from './reducers/statusTaskInactiveReducer';
import statusTaskActiveReducer from './reducers/statusTaskActiveReducer';

const rootReducer = combineReducers({
  graphData: graphDataReducer,
  selectedNode: selectedNodeReducer,
  nodesForLink: nodesForLinkReducer,
  currentUser: currentUserReducer,
  challengeId: challengeIdReducer,
  challengeTitle: challengeTitleReducer,
  token: tokenReducer,
  status: statusReducer,
  statusSolution: statusSolutionReducer,
  knowledgeCategories: knowledgeCategoriesReducer,
  linkedNodes: linkedNodesReducer,
  linkedNodeActive: linkedNodeActiveReducer,
  statusTaskInactive: statusTaskInactiveReducer,
  statusTaskActive: statusTaskActiveReducer
});

export default rootReducer;
